import {i18n} from "boot/i18n";
import store from "src/store";

export const substringIf = (value, start, end, add = '') => {

  if (value.length > end) {
    return value.substring(start, end) + add;
  }
  return value;
}



export const can = (permission) => {
  let user = store.getters['auth/user'];

  return user.permissions.map(perm => perm.toLowerCase()).includes(permission.toLowerCase());
  ;
}




export const trimByChar = (str, chars) => {
  if (str !== undefined)
    return str.trim().split(chars).filter(Boolean).join(chars);
}

export const nl2br = (str, replaceMode, isXhtml) => {

  var breakTag = (isXhtml) ? '<br />' : '<br>';
  var replaceStr = (replaceMode) ? '$1' + breakTag : '$1' + breakTag + '$2';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);

}

/**
 * Generate initials from full name
 * @param name
 * @returns {string}
 */
export const initials = (name) => {

  if (name) {

    var array = name.split(' ');

    switch (array.length) {

      case 1:

        return array[0].charAt(0).toUpperCase();

        break;
      default:

        return array[0].charAt(0).toUpperCase() + array[array.length - 1].charAt(0).toUpperCase();
    }

  }
  return "";

}

export const arrayJoin = (array) => {
  if(array){
    return array.join(', ');
  }
}

export const translate = (string) => {
  if (string === 'updated') {
    return i18n.t(string);
  }
  if (string === 'created') {
    return i18n.t(string);
  }
  return i18n.t(string);
}

/**
 * Capitalize
 * @param s
 * @returns {string}
 */
export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}


export const yesNo = (value) => {
  if (value) {
    return translate('yes');
  } else {
    return translate('no');
  }
}

export const money = (value) => {

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return formatter.format(value);
}

export const percentage = (value) => {
  return parseFloat(value).toFixed(2) + '%';
}

export const  escapeHTML = (unsafeText) => {
  let div = document.createElement('div');
  div.innerHTML = unsafeText;
  return div.innerText.slice(0,230)+'...';
}

export const formatToDhms = (seconds) => {

  seconds = Number(seconds)
  var d = Math.floor(seconds / (3600 * 24))
  var h = Math.floor((seconds % (3600 * 24)) / 3600)
  var m = Math.floor((seconds % 3600) / 60)
  var s = Math.floor(seconds % 60)
  // console.log(d, h, m, s)
  var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days ") : ""
  var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : ""
  var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : ""
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : ""
  return dDisplay + hDisplay + mDisplay + sDisplay
}

/**
 * Common filters
 */
export default {
  initials,
  capitalize,
  trimByChar,
  arrayJoin,
  can,
  yesNo,
  money,
  nl2br,
  escapeHTML,
  percentage,
  formatToDhms,
}
