import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import auth from './store-auth';

import general from './store-general';
import steps from './store-steps';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    general,
    steps
  },
  // enable strict mode (adds overhead!)
  // for dev mode only
  strict: process.env.DEV,

  plugins: [createPersistedState({
    storage: window.localStorage,
    paths: ['auth.isLogged','auth.auth',]
  })]
})
