export default {

  app_name: 'NGA',

  auth_heading: 'Next Generation Advanced 911',
  auth_text: 'Technology to empower emergency response',
  login: 'Log in',
  email: 'E-mail',
  password: 'Password',
  auth_description: 'Login',

  forgotten_your_password: 'Forgotten your password?',
  forgot_your_password: 'Forgot Your password?',
  forgot_password_description: "Enter your email below and we'll send you reset password link",

  please_check_confidential_checkbox: 'Please check confidential checkbox',

  send_reset_link: 'Send Link',
  go_back_to_login: 'Go Back to Login',
  remember_me: 'Remember me',
  all: 'All',
  account: 'Account',
  help: 'Help',
  create: 'Create',
  incident_summaries: 'Incident Summaries',
  library: 'Library',
  version: 'Version',

  show_all_notifications: 'Show all Notifications',
  more: 'More',
  preview: 'Preview',
  display_also_as_button: 'Display also as button',
  dashboard: 'Dashboard',
  signed_in_as: 'Signed in as ',
  sign_out: 'Sign Out',
  account_settings: 'Account Settings',
  search: 'Search',

  create_ticket: 'Create Service Ticket',
  service_tickets: 'Service Tickets',

  add_group: 'Add group',
  and: 'and',
  or: 'or',
  add_rule: 'Add rule',
  btn_delete: 'Delete',
  profile: 'Profile',
  notifications: 'Notifications',
  password_change_you_have_been_logged_out: 'Password Updated. You have been logged out',
  no_matching_records_found: 'No matching records found',
  office_email: 'Office Email',
  contractor_office_email: 'Contractor Office Email',
  office_phone: 'Office Phone',
  tax_number: 'Tax Number',
  quick_edit: 'Quick Edit',
  edit_assignment: 'Edit Assignment',
  details: 'Details',
  details_in_new_tab: 'Details in new tab',
  content: 'Content',
  type: 'Type',
  cleared: 'Cleared',
  save: 'Save',
  delete_account: 'Archive Account?',
  you_will_lost: 'Are you sure your want to archive account? Your account will permanently archived. This cannot be undone. Before please remember to cancel Your active subscriptions.',
  type_delete_account: 'Type "archive account" to confirm',
  confirm_text: 'Confirm Text',
  remove: 'Remove',
  actions: 'Actions',

  size: 'Size',
  added_by: 'Added By',
  created_at: 'Created At',
  updated_at: 'Updated At',
  created_by: 'Created By',
  primary_image: 'Primary Image',
  show_labels_on_map: 'Show labels on map',
  update_billing_data: 'Update company billing data',
  work_title: 'Job Title',
  organization: 'Organization',
  organization_hint: 'Who you represent or work for',
  ext: 'Ext',
  mobile_phone: 'Mobile Phone',
  mobile_phone_hint: 'Example: +18773131111. We use mobile phone for Two-Factor Authentication and notifications',
  update_profile_settings: 'Update Profile Settings',
  upload_avatar: 'Upload Avatar',
  remove_avatar: 'Remove Avatar',
  avatar: 'Avatar',
  office_phone_ext: 'Ext',
  profile_updated: 'Profile Updated',
  account_deleted: 'Account Deleted. Good Bye',
  settings_updated: 'Settings Updated',
  avatar_upload_hint: 'Use a square picture. The maximum file size allowed is 800KB.',
  logo_upload_hint: 'The maximum file size allowed is 800KB.',
  avatar_uploaded: 'Avatar Uploaded',
  avatar_deleted: 'Avatar Deleted',
  date_setting: 'Date Settings',
  no_results: 'No results',
  date_format: 'Date Format',
  time_format: 'Time Format',
  time_zone: 'Time Zone',
  settings: 'Settings',
  full_name: 'Full Name',
  name: 'Name',
  phone_hint: 'Example: +18773131111',
  two_factor_auth: 'Two-Factor Authentication',
  two_factor_enabled: 'Two-Factor Enabled',
  two_factor_auth_hint: 'Increase your account\'s security by enabling Two-Factor Authentication (2FA)',
  dashboard_settings: 'Dashboard Configuration',
  enable_tickets_notifications: 'Enable Tickets Notifications (Create, Update)',
  enable_comments_notifications: 'Enable Comments Notification',
  enable_attachment_notifications: 'Enable Attachments Notifications',
  enable_shift_notifications: 'Enable Shift Notifications',

  check_your_inbox_we_have_send_you_reset_password_link: "Almost done. We'll send and email to {email} in 5 minutes. Open it up to reset your password",
  reset_your_password: 'Reset Your Password',
  password_repeat: 'Repeat Password',
  change_password: 'Change Password',
  new_password_saved: 'New password saved',
  after_successful_update: 'After a successful password update, you will be redirected to the login page where you can log in with your new password.',
  current_password: 'Current Password',
  new_password: 'New Password',
  save_password: 'Save Password',
  strong_password: `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
  confirm: 'Confirm',
  update: 'Update',
  no_search_results: 'No search results. Try to change search criteria',
  delete: 'Delete',
  clear: 'Clear',
  submit: 'Submit',
  filter: 'Filter',
  add_view: 'Add View',
  users: 'Users',
  create_user: 'Create User',
  update_user: 'Update User',
  return_all_records: 'Return all records',
  manage_custom_view: 'Manage Custom View',
  description: 'Description',
  choose_columns: 'Choose Columns',
  view_columns: 'View Columns',
  color: 'Color',
  private: 'Private',
  available_columns: 'Available Columns',
  system_name: 'System Name',
  cancel: 'Cancel',
  move: 'Move',
  global: 'Global',
  locked: 'Locked',
  field: 'Field',
  value: 'Value',
  value_1: 'Value 2',
  operator: 'Operator',
  data_saved: 'Data Saved',
  record_deleted: 'Record Deleted',
  click_on_record_to_view_details: 'Click on record to view details',
  are_you_sure_you_want_to_delete: 'Are You sure You want to delete? This operation cannot be undone.',
  are_you_sure_you_want_to_download_selected_attachments: 'Are You sure You want to download selected attachments? This may take a while',
  are_you_sure: 'Are You sure?',
  add_comment: 'Add Comment',
  comment: 'Comment',
  comment_added: 'Commend Added',
  before_change: 'Before Change',
  after_change: 'After Change',
  causer_name: 'User',
  action: 'Action',
  date: 'Date',

  email_is_required: 'Email is required',
  invalid_email_format: 'Invalid Email format',
  password_is_required: 'Password is required',

  you_must_accept_our_terms_and_conditions: 'You must accept out Terms and Conditions and Privacy Policy',
  invalid_text: 'Invalid text',
  invalid_phone_number: `{field} is not a valid phone number`,
  lease_wait_we_are_preparing_workspace_for_you: "<b>Please wait. Wa are preparing account for You</b>",
  options: 'Options',
  last: 'Last',
  history: 'History',
  last_90_days: 'History from last 90 days',
  comments: 'Comments',
  attachments: 'Attachments',
  describe: 'Describe',

  reply: 'Reply',
  newest: 'Newest',
  popular: 'Popular',
  oldest: 'Oldest',
  edit: 'Edit',
  comment_content: 'Add comment',
  notes: 'Notes',
  back: 'Back',

  upload_attachments: 'Upload  Attachments',
  attachments_file_size_limit: 'Single file to upload can have up to 10mb',
  attachments_deleted: 'Attachments Deleted',
  attachment_deleted: 'Attachment Deleted',
  download: 'Download',
  download_selected: 'Download Selected',
  deleted_selected: 'Delete Selected',

  time_settings: 'Time Settings',
  start_day_hour: 'Start Day Hour',
  end_day_hour: 'End Day Hour',
  choose_record: 'Choose Record',
  edit_record: 'Edit Record',

  inline: 'Inline',
  split_view: 'Split View',
  service_ticket: 'Service Ticket',
  close_split: 'Close Split View',
  reason_for_decline: 'Reason For Decline',
  close: 'Close',
  yes: 'Yes',
  no: 'No',
  search_query_should_have: 'Search must be at least 3 characters',
  additional_description: 'Additional Description',
  open: 'Open',
  open_in_new_window: 'Open in New Window',

  moreDots: 'More...',

  assigned: 'Assigned',
  by: 'by',
  no_comments: 'No comments. Be the first to add one',
  created: 'Created',
  property: 'Property',
  confirmed: 'Confirmed',
  last_login: 'Last Login',
  agree_to_marketing: 'Agree to Marketing',
  status: 'Status',
  marketing: 'Marketing',
  leads: 'Leads',
  accounts: 'Accounts',
  support: 'Support',
  tickets: 'Service Tickets',
  contacts: 'Contacts',
  solutions: 'Solutions',
  solution: 'Solution',
  job_title: 'Job Title',
  groups: 'Groups',
  roles: 'Roles',
  date_and_time: 'Date & Time',
  title: 'Title',
  ticket_number: 'Ticket Number',
  account_name: 'Account Name',
  contact_name: 'Contact Name',
  ticket_sent_to_atos: 'Ticket sent to Atos',
  ticket_sent_to_lumen: 'Ticket sent to Lumen',
  ticket_bound_to_external_system: 'Ticket bound to external system',
  circuit_id_note: 'circuit_id required for lumen integration',
  circuit_id: 'Circuit ID',
  are_you_sure_you_want_to_archive_this_ticket: 'Are You sure You want to archive this ticket?',
  ticket_archived: 'Ticket archived',
  archive: 'Archive',
  unarchive: 'Unarchive',
  ticket_unarchived: 'Ticket unarchived',
  archived: 'Archived',
  archived_at: 'Archived At',
  Email: 'Email',
  Phone: 'Phone',
  integration_not_enabled_in_setting: 'Integration not enabled in settings. Please enable before continue',

  permissions: 'Permissions',
  select_all: 'Select All',
  website: 'Website',
  account_number: 'Account Number',
  employees: 'Employees',
  active: 'Active',
  is_active: 'Active',
  assignment: 'Assignment',
  contact: 'Contact',
  address: 'Address',

  industry: 'Industry',
  rating: 'Rating',
  contact_phone: 'Phone',
  contact_email: 'Contact E-mail',
  contact_secondary_email: 'Secondary E-mail',
  fax: 'Fax',
  address_street: 'Street',
  address_city: 'City',
  address_zip_code: 'Zip Code',
  workstation: '# of seat/positions',

  address_state: 'State',
  address_country: 'Country',
  population: 'Population',
  country: 'Country',
  group: 'Group',
  assigned_to: 'Assigned To',
  birth_date: 'Birth Date',
  phone: 'Phone',
  street: 'Street',
  mobile: 'Mobile',
  city: 'City',
  zip_code: 'Zip Code',
  locations: "PSAP Locations",
  state: 'State',
  category: 'Category',
  problem: 'Problem',
  resolution: 'Resolution',
  topic: 'Topic',
  configuration_item: 'Configuration Item',
  short_description: 'Short Description',

  long_description: 'Long Description',
  outage_type: 'Outage Type',

  open_by: 'Opened By',
  incident_start_date: 'Trouble Start Date - UTC',
  ticket_outage: 'Outage Type',
  owner: 'Owner',
  number: 'Number',
  vendor_number: 'External Ticket Number',
  priority: 'Priority',
  severity: 'Severity',
  ticket_work_times: 'Time Sheet',
  time_in: 'Time In',
  time_out: 'Time Out',
  double_time: 'Double Time',
  regular_time: 'Regular Time',
  overtime: 'Overtime',
  travel_time: 'Travel Time',
  ticket_timesheet: 'Time Sheet',
  ticket_work_times_name: 'User',
  please_use_dot_as_decimal_separator: 'You can use dot as decimal separator.',
  ticket: 'Service Ticket',
  choose_ticket: 'Choose Service Ticket',
  user: 'User',

  atos_ticket: 'Atos Ticket',
  updated: 'Updated',
  impact: 'Impact',
  csv_export: 'CSV',
  xlsx_export: 'Excel',
  opened_at: 'Opened At',
  contact_type: 'Contact Type',
  u_external_ticket_source: 'U External Ticket Source',
  u_external_ticket_number: 'U External Ticket Number',
  u_receiver: 'U Receiver',
  caller_id: 'Caller Id',
  u_caller_id: 'U Caller Id',
  u_short_description: 'U Short Description',
  u_description: 'U Description',
  u_state: 'U State',
  u_informational: 'U Informational',
  u_assignment_group: 'U Assignment Group',
  assignment_group: 'Assignment Group',
  subcategory: 'Subcategory',
  u_sub_category_l2: 'U Sub Category L2',
  u_psap_id: 'U Psap Id',
  u_work_notes: 'U Work Notes',
  work_notes: 'Work Notes',
  additional_comments: 'Additional Comments',
  close_notes: 'Close Notes',
  close_code: 'Close Code',
  resolved_at: 'Resolved At',
  ticket_completion: 'Ticket Completion',
  change_request_completion: 'Change Request Completion',
  ebonding: 'E-Bonding',
  completion: 'Ticket Completion',
  big_preview: 'Big Preview',
  internal_comment: 'This comment is internal',
  internal_comment_info: 'Internal note',
  login_as_user: 'Login as User',
  will_not_be_sent_to_external_system: 'Will not be sent to the external system',
  uri: 'Uri',
  u_comments: 'U Comments',
  psap_profiles: 'Psap Profiles',
  location: 'Location',
  PSAP: 'PSAP',
  FIRE: 'FIRE',
  Central: 'Central',
  La: 'La',
  Active: 'Active',
  Inactive: 'Inactive',
  notification_deleted: 'Notification Deleted',
  'Emergency Shutdown': 'Emergency Shutdown',
  Live: 'Live',
  Planned: 'Planned',
  'No Plan': 'No Plan',
  ATT: 'ATT',
  Frontier: 'Frontier',
  'Unique ID': 'Unique ID',
  region: 'Region',
  longitude: 'Longitude',
  web_text: 'Web Text',
  psap_id: 'PSAP ID',
  esn_wireless: 'ENS Wireless',
  esn_wireline: 'ENS Wireline',
  compound_location: 'Compound Location',
  latitude: 'Latitude',
  adr_base_url: 'Adr Base Url',
  lis_base_url: 'Lis Base Url',
  return_provided_by_block: 'Return <provided-by> Block',
  selective_route: 'Selective Route',
  sms_overflow_route: 'Sms Overflow Route',
  sms_session_limit: 'Sms Session Limit',
  sms_failure_route: 'Sms Failure Route',
  bandwith: 'Bandwith',
  sr_lec: 'Sr Lec',
  no_of_pos_range: '# OF POS RANGE',
  'Call ID': 'CALL ID',
  'Single tuple': 'Single Tuple',
  'Separate tuples': 'Separate Tuples',
  integrated_text: 'Integrated Text',
  tuple_id_source: 'Tuple ID Source',
  media_type: 'Media Type',
  endpoint: 'Endpoint',
  endpoints: 'Endpoints',
  add: 'Add',
  is_ro_admin: 'Is RO Admin',
  members: 'Members',
  member: 'Member',
  esns: 'ESNs',
  esn: 'ESN',
  canned_messages: 'Canned Responses',
  canned_message: 'Canned Response',
  canned_message_groups: 'Canned Response Groups',
  ca_911_ng_site_survey: 'CA 9-1-1 NG SITE SURVEY',
  site_details: 'Site Details',
  psap_site_name: 'Psap Site Name',
  fcc_id: 'FCC ID',
  psap_address: 'Psap Address',
  preferred_cutover_timeframe: 'Preferred Cutover Timeframe?',
  preferred_maintenance_window: 'Preferred Maintenance Window?',
  survey: 'Survey',
  zip: 'Zip',
  message: 'Message',
  property_information: 'Property Information',
  pspa_contacts: 'Psap Contacts',
  facility_preliminary_information: 'Facility Preliminary Information',

  building_condition: 'Building Condition',
  secure_access: 'Secure Access?',
  room_911_location: '911 Equip Room Location',
  escort_required: 'Escort Required?',
  equipment_room_floor_level: 'Equipment Room Floor Level',
  elevator_available: 'Elevator Available?',
  send_to_attention_to: 'Send To "Attention To"',
  shipping_information: 'Shipping Information',
  loading_doc_available: 'Loading Doc Available?',
  lift_truck_required: 'Lift Truck Required?',
  physical_address: 'Physical Address',
  psap_contacts: 'Psap Contacts',
  if_building_alterations_are_required: 'If building alterations are required, who can authorize?',
  alterations_could_include_but_not_be_limited_too_items: '(Alterations could include but not be limited too items such as electrical, construction, hvac, cable pulls, etc..)',
  alterations_owner: 'Owner or Manager',
  psap_description: 'PSAP Description',
  psap_description_describe: "Please DESCRIBE in Narrative form the agency and any special characteristics. Which would present issues to the Service Provider",
  site_survey_check_list: 'Site Survey Check List',
  please_ensure_all_items_have_been_addressed: 'Please ensure all items have been addressed or provided on this Site Survey',
  sta_details: 'Provided STA details?',
  provided_circuit_lists_on_circuits_tab: 'Provided Circuit Lists on Circuits Tab?',

  provided_copies_of_contingency_plans: 'Provided Copies of Contingency Plans?',
  provided_alternate_psap_details_policy: 'Provided Alternate PSAP details Policy?',
  provided_backup_psap_details_policy: 'Provided BACKUP PSAP details Policy?',
  provided_abandon_psap_details_policy: 'Provided ABANDON PSAP details Policy?',
  provided_busy_overflow_psap_details_policy: 'Provided BUSY-OVERFLOW PSAP details Policy?',
  provided_preferred_maintenance_window_details: 'Provided Preferred Maintenance Window details?',
  provided_login_user_account_info_details_for_texting: 'Provided login-user account info details for texting?',
  provided_details_on_upcoming_cpe_or_interface_changes: 'Provided Details on upcoming CPE or Interface changes?',
  provided_current_psap_bandwidth_requirementsbuildout: 'Provided Current PSAP Bandwidth Requirements/buildout?',

  cpe_details: 'CPE Details',
  text_details: 'Text Details',
  equipment_room: 'Equipment Room',
  equipment_room_layout: 'Equipment Room Layout',
  backup_power: 'Backup Power',
  facilities: 'Facilities',
  facilities_images: 'Facilities Images',

  psap_site_survey_cpe_detail_info: 'PSAP SITE SURVEY - CPE DETAIL INFO',
  current_system_details: 'Current System Detail',
  please_list_current_configuration_and_details: 'Please list current configuration and details',


  current_system_mfg: 'Current System MFG?',
  current_system_model: 'Current System model?',
  geodiverse_deployment: 'GeoDiverse Deployment?',
  hosted_solution_details: 'Hosted Solution Details?',
  psap_wph0_wph1wph2: 'PSAP WPH0, WPH1,WPH2?',
  phone_system_integrated: 'Phone system integrated?',
  alternate_answer: 'Alternate Answer?',
  overflow_psap_policy: 'Overflow PSAP Policy?',
  contingency_plan: 'Contingency Plan?',
  alt_answer_switch_push_or_pull: 'Alt Answer Switch, Push or Pull?',
  spare_ports_in_cpe_for_testing: 'Spare Ports in CPE for Testing?',
  any_upcoming_cpe_changes: 'Any Upcoming CPE changes?',
  no_of_consolesworkstations: '# of Consoles/workstations?',
  no_of_consoles_used_per_shift: '# of Consoles used per shift?',
  cpe_capability_i3_tdm: 'CPE Capability (i3, TDM)?',
  upcoming_psap_consolidations: 'Upcoming PSAP consolidations?',
  no_of_9_1_1_trunks: '# of 9-1-1 Trunks?',
  trunk_type_cama_pri_sip: 'Trunk Type (CAMA, PRI, SIP)?',
  average_call_duration_time: 'Average Call Duration Time?',
  annual_call_volume: 'Annual Call Volume?',
  trunk_digit_format_781020: 'Trunk Digit Format (7,8,10,20)?',
  current_trunk_provider: 'Current Trunk Provider?',
  test_lines_available_for_testing_9_1_1_calls_on_the_night_of_cut_cant_be_cell_phones: 'Test lines Available for testing 9-1-1 calls on the night of cut (Can\'t be Cell Phones)?',
  text_to_911_live: 'Text to 911 live?',
  text_call_type_integratedott: 'Text call type (integrated/ott?)',
  current_tcc_provider: 'Current TCC Provider?',
  text_annual_call_volume: 'Text Annual Call Volume?',
  text_routingx_fer_policies: 'Text Routing/x-fer Policies?',
  updated_text_call_flow_required: 'Updated Text Call Flow required?',
  current_ali_provider: 'Current ALI Provider',
  current_ali_format: 'Current ALI Format',
  current_ali_int_type_serialip: 'Current ALI Int Type: Serial/IP',
  current_ali_managed_by: 'Current ALI Managed by',
  ali_database_located_off_site_in_provider_facility_or_on_site_at_psap: 'ALI Database located off-site in provider facility or on-site at PSAP?',
  current_psap_bandwidth_requirements: 'Current PSAP Bandwidth Requirements',
  no_of_administrative_phone_sets: '# of administrative phone sets?',
  where_are_admin_sets_located: 'Where are admin sets located?',
  what_are_the_admin_sets_used_for: 'What are the admin sets used for?',
  acd_utilized: 'ACD Utilized?',
  current_call_routing: 'Current Call Routing?',
  max_no_of_queued_calls: 'Max # of Queued calls?',
  max_no_of_active_calls: 'Max # of Active Calls?',
  comments_or_special_shipping_instructions: 'Comments or Special Shipping Instructions',
  cad_vendor_gis_integration: 'CAD Vendor (GIS Integration)',
  cad_mfg: 'CAD MFG?',
  cad_version: 'CAD Version?',
  cad_vendor_available: 'CAD VENDOR AVAILABLE?',
  map_vendor_gis_integration: 'MAP Vendor (GIS Integration)',
  map_mfg: 'MAP MFG?',
  map_version: 'MAP Version?',
  map_vendor_available: 'MAP VENDOR AVAILABLE?',
  i3_logging_required_at_start: 'i3 Logging required at Start?',
  admin_policys_are_controlled_at_regionalcountylocal_level: 'Admin Policy\'s are controlled at Regional/County/Local level?',
  are_there_any_future_consolidations_being_planned_or_discussed: 'Are there any future consolidations being planned or discussed?',
  do_you_have_plans_to_replace_or_modify_the_interface_to_any_911_cpe: 'Do you have plans to replace or modify the interface to any 911 CPE ?',

  this_section_only_relevant_for_acd_systems: 'This section only relevant for ACD Systems',
  this_section_only_relevant_if_gis_integration_occurs: 'This section only relevant if GIS integration occurs',

  current_text_method_over_the_top_integrated_text_to_tty: 'Current text method? (Over the top, integrated, text to tty)?',
  number_of_calls_in_queue: 'Number of Calls in Queue?',
  number_of_concurrent_sessions_per_pos: 'Number of concurrent sessions per POS?',
  types_of_queues: 'Types of Queues?',
  ring_timeout_thresholds: 'Ring Timeout Thresholds?',
  idle_timer: 'Idle Timer?',
  how_to_handle_abandoned_text_calls_queued_or_distributed_to_agents: 'How to handle abandoned text calls (Queued or distributed to agents)?',
  please_provide_user_account_info_on_last_tab_with_default_passwords: 'Please provide user account info on last tab with default passwords',
  root_cause_analysis_required: 'Root Cause Analysis Required?',
  room_number_or_closet_id: 'Room Number or Closet ID',
  verify_demarc_located_in_this_telco_room: 'Verify demarc located in this Telco Room?',
  verify_closet_door_secured_means_suitable: 'Verify closet door secured? Means? Suitable?',
  verify_closet_space_appears_adequate_for_use: 'Verify closet space appears adequate for use?',
  verify_closet_ceiling_concerns_type_approx_height: 'Verify closet ceiling concerns? Type? Approx height?',
  verify_room_seems_adequately_lit_and_cooled: 'Verify room seems adequately lit and cooled?',
  verify_room_reasonably_clean_and_uncluttered: 'Verify room reasonably clean & uncluttered?',
  verify_room_has_space_for_equip_rackcabinet_minimum_40_x_50_cabinet: 'Verify room has space for equip rack/cabinet (Minimum 40" x 50" Cabinet)?',
  verify_concerns_with_locations_of_sprinkler_heads_andor_water_stains: 'Verify concerns with locations of sprinkler heads &/or water stains?',
  verify_network_interface_equip_pop_is_installed_where: 'Verify Network Interface equip / POP is installed? Where?',
  identify_existing_ntenids_pop_qty_and_type: 'Identify existing NTE/NIDs / POP ? Qty & type?',
  identify_existing_ntenids_shelf_array_or_individually_mounted: 'Identify existing NTE/NIDs - shelf (array) or individually mounted?',
  verify_existing_power_outlet_near_demarcpop: 'Verify existing power outlet near Demarc/POP?',
  verify_ntenidpop_power_cords_typ_6ft_reach_ups_without_issues: 'Verify NTE/NID/POP power cords (typ 6ft) reach UPS without issues?',
  verify_proper_ground_avail_where_connection_type: 'Verify proper ground avail? Where? Connection type?',
  verify_if_cable_tray_or_trough_extendeds_to_wall_field_from_rack_location: 'Verify if cable tray or trough extendeds to wall field from Rack location?',
  identify_any_environmental_issues_dont_existasbestos_voltage_etc: 'Identify any environmental issues don\'t exist(asbestos, voltage, etc)?',

  a_more_suitable_location_needed_for_ntenidpop_location: 'A more suitable location needed for NTE/NID/POP location?',
  identify_if_closet_security_needed_suitable_door_lock_etc: 'Identify if closet security needed (suitable door, lock, etc)?',
  identified_if_equipment_cooling_needs_to_be_improved: 'Identified if Equipment cooling needs to be improved?',
  idenitify_if_closet_lighting_needs_to_be_addressed: 'Idenitify if closet lighting needs to be addressed?',
  identify_if_any_sprinkler_system_heads_need_to_be_moved: 'Identify if any sprinkler system (heads) need to be moved?',
  identify_if_closet_needs_to_be_uncluttered_andor_organized: 'Identify if closet needs to be uncluttered &/or organized?',
  identify_if_dedicated_power_outlet_needed_for_new_ntenidpop: 'Identify if dedicated power outlet needed for new NTE/NID/POP?',
  idenitfy_if_proper_ground_needed_for_new_ntenid_pop_andor_rack: 'Idenitfy if proper ground needed for new NTE/NID POP &/or rack?',
  identify_any_environmental_concerns_need_to_be_addressed_by_psap: 'Identify any environmental concerns need to be addressed by PSAP?',
  other_remediation_needed_to_make_ready_for_service: 'Other Remediation needed to make ready for service?',
  roomremediation_ready_date_set_for_date: 'Room/Remediation ready date set for? [date]',

  conduits_coming_from_the_easement: 'Conduits coming from the easement',
  existing_demarcation_for_incoming_circuit: 'Existing Demarcation for incoming circuit.',
  location_designated_for_new_lec_nte: 'Location designated for new LEC NTE',
  doors: 'Doors',
  position_of_existing_conduits: 'Position of existing conduits',
  location_of_any_avail_dedicated_110v_20a_outlets: 'Location of any avail dedicated 110v 20a outlet(s).',
  identify_the_closest_electrical_panelbreaker_box: 'Identify the closest electrical panel/breaker box',
  distance_from_electrical_panel_to_circuit_demarcation_point: 'Distance from electrical panel to circuit demarcation point',
  existing_wallboards_racks_shelves_etc: ' Existing wallboards, racks, shelves, etc',
  potential_areas_for_new_wallboards_free_stand_or_wall_mtd_half_rack: 'Potential areas for new wallboards, free-stand or wall mtd half-rack',
  room_width: 'Room Width',
  ft: 'Ft',
  room_length: 'Room Lenght',
  ceiling_height: 'Ceiling Height',
  approximate_scale_used: 'Approximate Scale Used',

  is_there_a_ups_supporting_the_facility: 'Is there a UPS supporting the facility?',
  is_there_a_generator_supporting_the_facility: 'Is there a generator supporting the facility?',
  does_the_ups_have_capacity_to_cover_the_time_before_the_generator_takes_over: 'Does the UPS have capacity to cover the time before the generator takes over?',
  how_long_can_the_generator_run_before_needing_to_be_refueled: 'How long can the generator run before needing to be refueled?',
  what_is_the_refuel_plan: 'What is the refuel plan?',

  primary_facility_type: 'Primary Facility Type?',
  alternatebackup_facility_type: 'Alternate/Backup Facility Type?',
  primary_facility_provider: 'Primary Facility Provider?',
  alternatebackup_facility_provider: 'Alternate/Backup Facility Provider?',
  primary_facility_owner: 'Primary Facility Owner?',
  alternatebackup_facility_owner: 'Alternate/Backup Facility Owner?',
  distance_to_provider_coso: 'Distance to provider CO/SO?',
  facility_quality_appearance: 'Facility Quality / Appearance?',
  cable_owner_provideragency: 'Cable Owner (provider/agency)?',
  underground_aerial_entrance: 'Underground / Aerial Entrance?',
  campus_or_provider_maintained: 'Campus or Provider maintained?',
  demarcation_clearly_identified: 'Demarcation clearly identified?',
  facility_manager_identified: 'Facility manager identified?',
  building_terminal_insideoutside: 'Building terminal inside/outside?',
  copper_cable_house_or_telco_cable: 'Copper cable house or telco cable?',
  dual_entrance_available: 'Dual Entrance Available?',
  additional_copper_facilities_required: 'Additional copper facilities required?',
  dual_entrance_utilized_at_psap: 'Dual Entrance utilized at PSAP?',
  fiber_cable_house_or_telco_cable: 'Fiber cable house or telco cable?',
  dual_entrance_available_2: 'Dual Entrance Available?',
  additional_fiber_facilities_required: 'Additional Fiber facilities required?',
  dual_entrance_utilized_at_psap_2: 'Dual Entrance utilized at PSAP?',
  demarc_located_in_equipment_room: 'Demarc located in Equipment room?',
  telco_and_equipment_room_same: 'Telco & Equipment Room same?',
  distance_to_equipment_room: 'Distance to Equipment room?',
  will_cable_pull_be_complex: 'Will Cable pull be complex?',
  will_walls_need_penetrated: 'Will walls need penetrated?',
  will_wire_run_in_the_ceiling: 'Will wire run in the ceiling?',
  will_outside_walls_need_penetrated: 'Will outside walls need penetrated?',
  will_psapprovider_pull_cable: 'Will PSAP/Provider pull Cable?',
  specific_color_required: 'Specific Color required?',
  will_provider_pull_facility_cable: 'Will Provider pull facility Cable?',
  is_there_a_local_microwave_network_that_your_psap_is_connected_to_or_avaliable: 'Is there a local microwave network that your PSAP is connected to or avaliable?',

  cables_coming_from_the_utility_easement: 'Cables coming from the utility easement',
  existing_demarcation_for_facility_termination: 'Existing Demarcation for facility termination',
  location_designated_for_new_facilities: 'Location designated for new Facilities',

  unsaved_changes: 'There are unsaved changes, do you want to continue?',
  checklist: 'Checklist',

  psap_site_survey_text_configuration_info_prime_only: 'PSAP SITE SURVEY - TEXT CONFIGURATION INFO (PRIME ONLY)',
  texting_details: 'Texting Details',
  new_system_configuration_and_details: 'New system configuration and details',
  psap_site_survey_equipment_room_information: 'PSAP SITE SURVEY - EQUIPMENT ROOM INFORMATION',
  provide_details_on_the_equipment_room: 'PROVIDE DETAILS ON THE EQUIPMENT ROOM',
  note_use_this_form_for_datacenterequipment_rooms_make_additional_copies_if_needed_be_sure_to_complete_diagramspics_section_to_assist_with_this_section: 'Note: Use this form for Datacenter/Equipment Rooms (make additional copies if needed). Be sure to complete diagrams/pics section to assist with this section.',
  backroom_details: 'BACKROOM DETAILS',
  concerns: 'CONCERNS',
  psap_site_survey_equipment_room_images_layout: 'PSAP SITE SURVEY - EQUIPMENT ROOM IMAGES / LAYOUT',
  provide_a_sketch_of_the_equipment_room_layout: 'PROVIDE A SKETCH OF THE EQUIPMENT ROOM LAYOUT',
  note_use_this_form_for_datacenterequipment_rooms_make_additional_copies_if_needed: 'Note: Use this form for Datacenter/Equipment Rooms (make additional copies if needed).',
  psap_site_survey_backup_power: 'PSAP SITE SURVEY - BACKUP POWER',
  provide_details_on_the_backup_power: 'PROVIDE DETAILS ON THE BACKUP POWER',
  psap_site_survey_dispatch_configuration_info: 'PSAP SITE SURVEY - DISPATCH CONFIGURATION INFO',
  provide_detailed_leccable_facilities_for_current_lecs: 'PROVIDE DETAILED LEC/CABLE FACILITIES FOR CURRENT LEC(S)',
  note_if_able_to_capture_screen_shots_or_pictures_which_will_provide_additional_details_please_insert_into_equipment_room_layout_layout_tab: 'Note: If able to capture screen shots or pictures which will provide additional details, please insert into Equipment Room Layout layout tab.',
  psap_enterance_facilities_work_with_it_staff_for_details: 'PSAP ENTERANCE FACILITIES (WORK WITH IT STAFF FOR DETAILS)',
  please_list_current_facility_details_these_are_referring_to_the_facilities_leading_up_to_demarc: 'Please list current facility details. These are referring to the facilities leading up to DEMARC.',
  please_list_any_additional_training_requirements_in_comments: 'Please list any additional training requirements in comments',
  local_microwave_availabilty_prime_only: 'LOCAL MICROWAVE AVAILABILTY (PRIME ONLY)',
  note_use_this_form_for_telcoprovider_equipment_rooms_make_additional_copies_if_needed: 'Note: Use this form for Telco/provider Equipment Rooms (make additional copies if needed). Note #2: if Same as Equipment room, please complete that tab instead of this tab.',
  atos_id: 'ATOS ID',
  adding_images_possible_after_creating_psap_profile: 'Adding attachments is possible after creating Psap Profile',
  adding_images_possible_after_creating_entry: 'Adding attachments is possible after creating entry',
  devices: 'Devices',
  properties: 'Properties',
  configuration_items: 'Configuration Items',
  attach_selected: 'Attach selected',
  detach_selected: 'Detach selected',
  select: 'Select',
  attach: 'Attach',
  all_day: 'All Day',
  limited_access: 'Limited Access',
  change_requests: 'Change Requests',
  scheduled_start: 'Scheduled Start',
  scheduled_end: 'Scheduled End',
  inventory: 'Inventory',
  change_request_number: 'CR Number',
  scheduler: 'Scheduler',
  refresh: 'Refresh',
  your_device_time_zone_deos_not_match_configured: "Your device's time zone does not match configured in the application account.",

  api_authorization: 'API AUTHORIZATION',
  calendar_token: 'Calendar Token',
  api_key: 'Api Key',
  text_copied_to_clipboard: 'Text copied to clipboard',
  generate_new_api_key: 'Generate new api key',
  generate_new_calendar_token: 'Generate new calendar token',
  are_you_sure_you_want_to_generate: 'Are You sure You want to generate new api key?',
  extra_attributes: 'Extra Attributes',
  incident_summary: 'Incident Summary',
  incident_details: 'Incident Details',
  currency_sign: '$',
  deals: 'Deals',
  source: 'Source',
  amount: 'Amount',
  total_contract_value: 'Total Contract Value',
  call_date: 'Call Date',
  conversation_date: 'Activity Date',
  phone_email: 'Phone/Email',
  min: '(min.)',

  contract_length: 'Contract Term (Years)',
  lost_reason: 'Lost Reason',
  next_step: 'Next Step',
  stage: 'Stage',
  funnel_stage: 'Funnel Stage',
  probability: 'Probability (%)',
  close_date: 'Close Date',
  deal_account: 'Account',
  additional_information: 'Additional Information',

  fields: 'Fields',
  valid_import_files: 'Valid files: csv, xls, ods',
  your_file_must_include_header_row: 'File must include header row.',
  start_required: 'Fields with * are required.',

  import_contacts_fields: 'Name*, Birth Date, Status, Type, Phone, Mobile, Email*, Phone, Street, Zip Code, State, Country, Account, Assigned To, Group, Notes, Solution, Fax, Job Title ',

  import_accounts_fields: 'Name*, Tax Number, Website, Account Number, Employees, Type, Industry, Rating, Contact Phone, Contact Email, Secondary Email, Fax, Street, City, Zip Code, State, Country, Assigned To, Group, Description, Solution, Status, Stage',
  import_deals_fields: 'Name*, Owner, Account, Close Date, Type, Stage, Probability, Amount, Budget Confirmed, Discovery Completed, ROI Analysis Completed, Lost Reason, Contract Length, Next Step, Source, Description, Lgu Region, Status, Contact, Last Interaction',
  map_columns: 'Map columns',
  delimiter_csv: 'Delimiter (for CSV)',
  validate_and_import: 'Validate and Import',
  records_to_import: 'records to import',
  application_column: 'Application Column',
  file_column: 'File Column',
  imported_rows: 'Imported rows',
  duplicated_and_skipped_rows: 'Duplicated and skipped rows',
  import_result: 'Import result',
  finish: 'Finish',
  import: 'Import',
  upload_file: 'Upload file',
  all_rows: 'All Rows',
  attachment: 'Attachment',
  attachment_exel_hint: 'Valid file types: xls, csv, ods. Up to 10mb',
  upload: 'Upload',
  skip_duplicates: "Skip rows with duplicated {name}.",
  on_error: 'What to do on row error?',
  skip_on_error: 'Skip row on error',
  break_on_error: "Break and don't import",
  records_imported: 'Records imported',
  add_record: 'Add New',
  status_id: 'Status',
  type_id: 'Type',
  address_country_id: 'Country',
  account_id: 'Account',
  assigned_id: 'Assigned',
  group_id: 'Group',
  solution_id: 'Solution',
  required: 'Required',
  owner_id: 'Owner',
  stage_id: 'Stage',
  lost_reason_id: 'Lost Reason',
  source_id: 'Source',
  location_tags: 'Location Tags',
  location_tag: 'Location Tag',
  assign_location_tag: 'Assign Location Tag',
  are_you_sure_you_want_to_assign_tag_to_selected_locations: 'Are You sure You want to assign selected tag to locations?',
  setup_charge: 'Setup Charge',
  monthly_charge: 'Monthly Charge',
  subject: 'Subject',
  deal: 'Deal',
  direction: 'Direction',
  duration: 'Duration',
  calls: 'Activity',
  last_6_months: 'Last 6 months',
  last_year: 'Last Year',
  last_2_years: 'Last 2 Years',
  last_3_years: 'Last 3 Years',
  month: 'Month',
  tcv: 'Total Contract Value',
  total_contract_value_by_close_date: 'TVC by Close Date',
  total_contract_value_by_deal_owner: 'TVC by Deal Owner',
  total_contract_value_by_stage: 'TVC by Stage',
  total_contract_value_by_state: 'TVC By State',
  buttons: 'Buttons',
  label: 'Label',
  target: 'Target',
  url: 'Url',
  position: 'Position',
  left_menu: 'Left Menu',
  top_bar: 'Top Bar',
  self: 'Self',
  new_window: 'New Window',
  landing_page: 'Landing Page',
  continue: 'Continue',
  what_you_want_to_do: 'What you want to do ?',

  please_select_one_of_the_following_options: "Please select one of the following options",

  ex_select_equal: 'equals',
  ex_select_not_equal: 'is not equal to',
  ex_select_begins_with: 'begins with',
  ex_select_not_begins_with: 'does not begin with',
  ex_select_contains: 'contains',
  ex_select_not_contains: 'does not not contains',
  ex_select_ends_with: 'ends with',
  ex_select_not_ends_with: 'does not ends with',
  ex_select_is_empty: 'is blank',
  ex_select_is_not_empty: 'is not blank',
  ex_select_in: 'includes',
  ex_select_less: 'is less than / is before (for dates)',
  ex_select_less_or_equal: 'is less than or equal to',
  ex_select_greater: 'is greater than / is after (for dates)',
  ex_select_greater_or_equal: 'is greater than or equal to',
  ex_select_between: 'is between',
  ex_select_not_between: 'is not between',
  ex_select_not_in: 'does not include',

  ticket_notification_email: 'Tickets Notification Email',
  ticket_notifications: 'Ticket Notifications',
  ticket_notifications_description: 'Manage ticket notifications content',
  ticket_technician_dispatch: 'Dispatch Technician Notification',
  ticket_technician_in: 'Technician In Notification',
  ticket_technician_out: 'Technician Out Notification',
  ticket_technician_resolved: 'Technician Resolved Notification',

  tags: 'Tags',
  send_ticket_notification_to_this_email: 'Send Ticket Notifications to this email',
  lgu_region: 'Lgu Region',
  lgu_class: 'Lgu Class',
  last_interaction: 'Last Interaction',
  contract_value: 'Contract Value',
  ex_chief_exec: 'Ex. Chief Exec',
  pipeline_stage: 'Pipeline Stage',
  is_internal: 'Internal Ticket',

  deals_open_by_current_stage: 'Deals open by current stage',
  filter_data: 'Filter Data',
  charts: 'Charts',
  from: 'Form',
  to: 'To',
  contact_activity: 'Contact Activity',
  data_range: 'Date Range',
  date_range: 'Date Range',
  compare_to: 'Compare To',
  load: 'Load',
  deals_total_by_stage: 'Deals total by stage',
  count: 'Count',
  not_set: 'Not Set',
  deal_revenue_forecast_by_owner_with_stage_breakdown: 'Deal Revenue Forecast by Owner with Stage Breakdown',
  total: 'Total',
  deals_revenue_by_close_date: 'Deals Revenue by Close Date',
  legend: 'Legend',
  filter_by_close_date: 'Filter by close date',
  filter_activity_date: 'Filter by activity date',
  deals_revenue_by_close_date_legend: 'Deals without close lost deals, grouped by close date ( year and month)',
  deals_open_by_current_stage_legend: 'Deals without close lost and close won, grouped by stage. ',
  deals_total_by_stage_legend: 'All deals grouped by stage',
  contact_activity_legend: 'Grouped Contacts Actions by Type',
  deals_closed_revenue_by_month: 'Deals Closed Revenue by Month',
  total_contract_count: 'Total Contract Count',
  deal_closed_revenue_by_month_legend: 'Closed Won deals grouped by  close date (year and month)',
  clear_filter: 'Clear filter',
  deals_revenue_forecast_by_stages: 'Deals Revenue Forecast by Stages',
  deal_stage: 'Deal Stage',
  deal_stage_amount: 'Deal Stage Amount',
  call_end_date: 'Activity End Date',
  load_report_data: 'Load report data',
  on_site_support: 'On Site Support Required',
  service_category: 'Service Category / Functional Element',
  origin: 'Origin',
  custom_origin: 'Custom Origin',

  service_delivery_kpi: 'Service Delivery KPI',
  classified_by_service_category: 'Tickets by Service Category',
  filter_by_create_date: 'Filter by Create Date',
  total_tickets: 'Total Tickets',
  add_date: 'Add Date',
  excel: 'Excel',
  download_raw_data: 'Download Raw Data',
  tickets_by_origin: 'Tickets by Origin',
  tickets_resulting_in_on_site_support: 'Tickets resulting in On Site Support',
  percentage: 'Percentage',
  tickets_requiring_on_site_support: 'Tickets Requiring On Site Support',
  average_time_to_resolution: 'Average Time to Resolution',
  ticket_time_to_resolution: 'Ticket Time to Resolution',
  ticket_open_time: 'Ticket Open Time',
  ticket_close_time: 'Ticket Close Time',
  average: 'Average',
  average_response_time: 'Average Response Time',
  average_resolution_time: 'Average Resolution Time',
  tickets_met: 'Tickets Met',
  tickets_not_met: 'Tickets Not Met',
  percentage_met: 'Percentage Met',
  percentage_not_met: 'Percentage Not Met',
  tickets_open_to_tickets_closed: 'Tickets Open to Tickets Closed',
  filter_by_incident_start_date: 'Filter by Incident Start Date',
  severity_level: 'Severity Level',
  level: 'Level',
  response_time: 'Response Time',
  resolution_time: 'Resolution Time',
  critical: 'Critical',
  high: 'High',
  moderate: 'Moderate',
  low: 'Low',
  six_minutes_or_less: '6 minutes or less',
  within_2_hours: 'Within 2 hours',
  ten_minutes_or_less: '10 minutes or less',
  within_4_hours: 'Within 4 hours',
  eight_hours_or_less: '8 hours or less',
  within_48_hours: 'Within 48 hours',
  sixteen_hours_or_less: '16 hours or less',
  within_96_hours: 'Within 96 hours',
  estimated_close_date: 'Estimated Close Date',
  quality: 'Quality',
  choose_date: 'Choose Date',
  click_on_calendar_to_choose_date: 'Click on calendar to choose date',
  icon: 'Icon',
  age: 'Age',
  gender: 'Gender',
  orientation: 'Orientation',
  ethnicities: 'Ethnicities',
  ethnicity: 'Ethnicity',
  languages: 'Languages',
  language: 'Language',
  pronouns: 'Pronouns',
  user_work_status: 'User Work Status',
  user_work_type: 'User Work Type',
  users_description: 'Change password, Reset password, Delete user, Add user, Edit user.',
  groups_description: 'Add and remove users from groups. Delete groups.',
  roles_description: 'Add and remove permissions from roles. Delete roles.',
  buttons_description: 'Add and remove buttons from roles. Delete buttons.',
  age_description: 'Add and remove callers age from callers. Delete callers age.',
  gender_description: ' Add and caller and user remove gender.',
  orientation_description: 'Add and remove orientation.',
  ethnicity_description: 'Add and remove ethnicity.',
  language_description: 'MAdd and remove language.',
  pronouns_description: 'Add and remove pronouns.',
  user_work_status_description: 'Add and remove work status.',
  user_work_type_description: 'Add and remove work type.',
  events: 'Events',
  other: 'Other',
  event_type: 'Event Type',
  event_source: 'Event Source',
  event_purpose: 'Event Purpose',
  event_training_type: 'Event Training Type',
  event_type_description: 'Add and remove event type.',
  event_source_description: 'Add and remove event source.',
  event_purpose_description: 'Add and remove event purpose.',
  event_training_type_description: 'Add and remove event training type.',
  hmcc: 'HMCC',
  event_date: 'Event Date',
  purpose: 'Purpose',
  training_type: 'Training Type',
  no_of_attendees: '# of Attendees (for each of)',
  attend_professionals: 'No of Professionals (Clinical, medical, first respond)',
  attend_teachers: 'No of Teachers',
  attend_students: 'No of Students',
  attend_general_public: 'No of General Public',
  funding: 'Funding',
  others: 'Others',
  note_category: 'Canned Response Category',
  note_category_description: 'Add and remove canned response category.',
  leaves: 'Time Off',
  date_from: 'Date From',
  date_to: 'Date To',
  call_scripts: 'Contact Scripts',
  list_of_answers: 'List of answers',
  intro: 'Intro',
  question: 'Question',
  questions: 'Questions',
  option: 'Option',
  supervisor: 'Supervisor',
  work: 'Work',
  personal_data: 'Personal Data',
  background: 'Background',
  hire_date: 'Hire Date',
  end_of_service_date: 'End of Service Date',
  address_line_1: 'Address Line 1',
  address_line_2: 'Address Line 2',
  postal_code: 'Postal Code',
  user_languages: 'Languages',
  approved: 'Approved',
  fill_field_to_change_password: 'Fill password field to update password',
  generate_password: 'Generate Password',
  subtitle: 'Subtitle',
  skills_description: 'Add and remove skills from users. Delete skills.',
  skills: 'Skills',
  user_skills: 'Skills & Achievements',
  finish_date: 'Finish Date',
  skill: 'Skill',
  not_done: 'Not Done',
  to_be_done: 'To Be Done',
  in_progress: 'In Progress',
  completed: 'Completed',
  waived: 'Waived',
  published: 'Published',
  draft: 'Draft',
  call_scripts_description: 'Manage call scripts. Add and remove call scripts.',
  add_next_question: 'Add Question',
  add_next_option: 'Add Option',
  related_articles: 'Related Articles',
  counties: 'Counties',
  counties_description: 'Manage counties. Add and remove counties.',
  relationship_types: 'Relationship Types',
  relationship_types_description: 'Manage relationship types. Add and remove relationship types.',
  mh_contacts: 'Profiles',
  age_group: 'Age Group',
  also_known_as: 'Also Known As',
  county: 'County',
  opening_line: 'Opening Line',
  permission_comments: 'Permission Comments',
  permission_comment: 'Permission Comment',
  is_regular_caller: 'Is a regular caller - appears in drop down list in call report forms',
  has_alert: 'Place an alert - will be highlighted on main Contacts page if an alert is places, it should expire',
  alert_expires_at: 'Alert Expires At',
  discusses_sex_content: 'Frequently discusses inappropriate sexual content',
  communication_instruction: 'Instructions when communicating with this person',
  assistance_instruction: 'Instructions in case assistance is needed',
  keywords: 'Keywords',
  contact_and_calls: 'Contact & Calls',
  has_alert_short: 'Has Alert',
  household: 'Household',
  relationship: 'Relationship',
  relationship_type: 'Type',
  call_script_template: 'Script Template',
  answer: 'Answer',
  choose_value: 'Choose Value',
  choose_template: 'Choose Template',
  back_to_articles: 'Back to Articles',
  other_articles_from_this_category: 'Other Articles from this Category',
  communication_channel_types: 'Communication Channel Types',
  communication_channel_types_description: 'Add and remove communication channel types.',
  communication_channels: 'Communication Channels',
  organization_name: 'Organization Name',
  resource_information: 'Resource Information',
  phone_numbers: 'Phone Numbers',
  link_to_map: 'Link to Map',
  link_to_website: 'Link to Website',
  hotline: 'Hotline',
  advocate_contact: 'Advocate Contact',
  coverage_area: 'Coverage Area',
  main_contact_number: 'Main Contact Number',
  transfer_to_number: 'Transfer to Number',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  open_from: 'Open From',
  open_to: 'Open To',
  closed: 'Closed',
  defined_coverage_area: 'Defined Coverage Area',
  program_fees: 'Program Fees',
  service_area: 'Service Area',
  outreach_materials: 'Outreach Materials',
  distribution_date: 'Distribution Date',
  distribution_qty: 'Distribution Qty',
  outreach_status: 'Outreach Status',
  language_offered: 'Language Offered',
  mou: 'MOU',
  agency_hours: 'Agency Hours',
  support_group: 'Support Group',
  location_information: 'Location Information',
  resource_categories: 'Resource Category',
  resource_categories_description: 'Add and remove resource category.',
  resource_types: 'Resource Type',
  resource_types_description: 'Add and remove resource type.',
  related_contact: 'Related Contact',
  related_profile: 'Related Profile',
  related_contact_name: 'Related Contact Name',
  related_profile_name: 'Related Profile Name',


  caller_name: 'Contact Name',
  mh_calls: 'Contacts',
  mh_call_types: 'Contact Type',
  mh_call_types_description: 'Add and remove contact type.',
  prompt_questions: 'Prompt Questions',
  assessment: 'Assessment',
  self_rated_intent: 'Self-Rated Intent',
  description_of_call_and_outcome: 'Description and Outcome',
  responder_comments_andor_caller_feedback: 'Responder Comments and/or Feedback',
  phone_type: 'Type',
  phone_number_ext: 'Ext',
  caller_location: 'Contact Location',
  caller_address: 'Contact Address',
  caller_city: 'Contact City',
  caller_county: 'Contact County',
  caller_state: 'Contact State',
  caller_zip_code: 'Contact Zip Code',
  contact_information: 'Contact Information',
  call_information: 'Contact Information',
  caller_demographic_information: 'Contact Demographic Information',
  please_gather_this_information_whenever_possible: 'Please gather this information whenever possible',
  call_duration: 'Duration',
  is_life_line_call: 'Is Life Line Call?',
  was_call_helpful: 'Was Call Helpful?',
  now: 'Now',
  issues: 'Issues',
  call_start: 'Conversation Start',
  mh_contact: 'Profile',
  call_end: 'Conversation End',
  people: 'People',
  worker: 'Worker',
  follow_up: 'Follow Up',
  further_action: 'Further Action',
  suicidal_capability: 'Suicidal Capability',
  suicidal_desire: 'Suicidal Desire',
  suicidal_intent: 'Suicidal Intent',
  safety_planning: 'Safety Planning',
  buffers_and_connectedness: 'Buffers and Connectedness',
  suicidal_thoughts_in_the_past_few_days_including_today: 'Suicidal thoughts in the past few days, including today',
  suicidal_actions_today: 'Suicidal actions today',
  if_a_third_party_call_select_risk_level_for_person_at_risk: 'If a third-party, select risk level for Person-at-Risk',
  select_no_only_if_answers_to_all_prompt_questions_are_no: 'Select No only if answers to all prompt questions are "No"',
  sri_start: 'Sri Start',
  sri_end: 'Sri End',
  feedback_from_supervisors_and_staff: 'Feedback from supervisors and staff',
  no_feedback_to_give_on_this_contact_form: 'No feedback to give on this contact form',
  are_they_at_risk: 'Are they at risk?',
  another_ethnicity: 'Another Ethnicity',
  another_age: 'Another Age',
  not_applicable: 'Not Applicable',
  available_means: 'Available Means',
  extreme_agitationdysregulated: 'Extreme Agitation/Dysregulated',
  increased_anxiety: 'Increased Anxiety',
  sleep_disturbance: 'Sleep Disturbance',
  history_of_violence_to_others: 'History of Violence to Others',
  exposure_to_suicide: 'Exposure to Suicide',
  acute_symptoms_of_mental_illness: 'Acute Symptoms of Mental Illness',
  reasons_for_living: 'Reasons for Living',
  history_of_self_harm: 'History of Self Harm',
  history_of_attempts: 'History of Attempts',
  document_details_explored: 'Document details explored',
  suicidal_plan: 'Suicidal Plan',
  preparatory_behaviors: 'Preparatory Behaviors',
  substance_abuse: 'Substance Abuse',
  suicidal_ideation: 'Suicidal Ideation',
  hopelessness: 'Hopelessness',
  attempt_in_progress: 'Attempt in Progress',
  self_hate: 'Self Hate',
  feeling_trapped: 'Feeling Trapped',
  psychological_pain: 'Psychological Pain',
  sense_of_purpose: 'Sense of Purpose',
  ambivalence: 'Ambivalence',
  perceived_burden: 'Perceived Burden',
  feeling_alone: 'Feeling Alone',
  core_beliefs: 'Core Beliefs',
  social_supports: 'Social Supports',
  planning_for_future: 'Planning for Future',
  immediate_supports: 'Immediate Supports',
  engagement_with_responder: 'Engagement with Responder',
  expressed_intent_to_die: 'Expressed Intent to Die',
  currently_intoxicated: 'Currently Intoxicated',
  legal_hold_not_editing_can_be_done: 'Legal Hold - Not Editing Can be Done',
  called_911: '911 Called +',
  welfare_check: 'Welfare Check +',
  suicide_watch: 'Suicide Watch +',
  dispatcher_badge_number: 'Dispatcher Badge Number',
  select_all_that_apply: 'Select All That Apply',
  called_with_caller: 'Called with Caller',
  details_given_to_operator: 'Details Given to Operator',
  ask_for_case_number: 'Please ask for the case number before you disconnect with the 911 operator.',
  case_number: 'Case Number',
  who: 'Who',
  channel_type: 'Channel',
  additional_details_for_suicide_watch: 'Additional Details for Suicide Watch',
  what_is_safety_plan: 'What is Safety Plan?',
  details_given_to_911: 'Details Given to 911',
  start_and_end_times: 'Start & End Times',
  did_you_speak_with_them_directly: 'Did You Speak with them Directly?',
  safety_plan: 'Safety Plan',
  safety_agreement: 'Safety Agreement',
  did_caller_hang_up_before_a_safety_plan_andor_safety_agreement_could_be_established: 'Did contact hang up before a safety plan and/or safety agreement could be established?',
  offered_follow_up_call: 'Offered Follow Up',
  best_time_range_for_call: 'Within the next 24 hours, best time range for contact? Please specify date and time range (ex. 12/20 from 2-6pm)',
  who_is_this_follow_up_call_for: 'Who is this follow-up for?',
  okay_to_identify_ourselves_as_suicide_prevention: 'Okay to identify ourselves as Suicide Prevention?',
  who_else_can_we_talk_to: 'Who else can we talk to?',
  ok_to_leave_a_message: 'Ok to leave a message?',
  staff_notified_of_follow_up_call_via_email_or_shift_supervisor: 'Staff notified of follow-up via email or Shift Supervision?',
  hangup_note: 'A hang up call is when you do not speak with a caller for any length of time. Only select this if you did not have any contact.',
  hangup_note_title: 'Hang Up Note',
  business_call_title: 'Business Contact Note',
  business_call_note: 'Please ask all the assessment questions and create a safety plan agreement.',
  follow_up_title: 'Follow Up Note',
  hang_uprobocall_plus: 'Hang Up/Robocall (eliminates required fields) + ',
  abusive_caller: 'Abusive (eliminates required fields)',
  shift_supervision: 'Shift Supervision (eliminates required fields)',
  test_call: 'Test (eliminates required fields)',
  tech_issue_bug: 'Tech Issue/Bug (eliminates required fields)',
  outbound_call: 'Outbound',
  outbound_call_to_911: 'Outbound to 911 (eliminates required fields)',
  third_party_plus: 'Third Party +',
  time_limited: 'Time Limited',
  follow_up_plus: 'Follow Up +',
  other_calls: 'Other Info',
  business_call_plus: 'Business Contact +',
  suicide_loss_survivor: 'Suicide Loss Survivor',
  did_caller_answer: 'Did Caller Answer?',
  if_resources_were_provided_in_original_call_were_they_able_to_access_if_not_why: 'If resources were provided in original call, were they able to access? If not, why?',
  third_party_title: 'Third Party Note',
  person_at_risk_location: 'Person at Risk Location',
  is_the_person_at_risk_alone: 'Is the person at risk alone?',
  did_you_speak_with_the_person_at_risk: 'Did you speak with the person at risk?',
  where_is_person_at_risk_now: 'Where is person at risk now ?',
  walfer_action_called_with_caller: 'Called with Caller?',
  prev: 'Prev',
  next: 'Next',
  no_feedback: 'No Feedback',
  vols_staff: 'Vold-Staff',
  feedback: 'Feedback',
  ready: 'Ready',
  not_ready: 'Not Ready',
  simulate_incoming: 'Simulate',
  resource_created: 'Resource Created',
  verification: 'Verification',
  last_verified: 'Last Verified',
  verified_by: 'Verified By',
  resources: 'Resources',
  resource_referrals: 'Resource Referrals',
  call: 'Call',
  resource: 'Resource',
  offline_message: 'You are offline. Please connect to internet to use application.',
  or_choose_from_existing: 'Or choose from existing',
  attributes: 'Attributes',
  recent_contacts: 'Recent Contacts',
  recent_calls: 'Recent Calls',
  mhcc: 'MHCC',
  application_settings_description: 'Manage application settings',
  application_settings: 'Application Settings',
  background_color: 'Background Color',
  application_message_configuration: 'Application Message Configuration',
  application_message_configuration_hint: 'Manage application message configuration. Display information or alert message.',
  application_message_show: 'Application message is visible',
  setting_updated: 'Setting updated',
  important_contact_numbers: 'Important Contact Numbers',
  important_contact_numbers_hint: 'Manage important contact numbers in contact form',
  stats: 'Stats',
  today_calls: 'Today Calls',
  past_7_days_calls: 'Past 7 Days Calls',
  past_30_days_calls: 'Past 30 Days Calls',
  past_90_days_calls: 'Past 90 Days Calls',
  average_call_length: 'Average Call Length',
  tasks: 'Tasks',
  task_type: 'Task Type',
  task_types: 'Task Types',
  task_types_description: 'Manage Task Types',
  add_new_task: 'Add New Task',
  add_15min: 'Add 15 minutes',
  add_30min: 'Add 30 minutes',
  add_1hr: 'Add 1 hour',
  add_2hr: 'Add 2 hours',
  add_4hr: 'Add 4 hours',
  add_time: 'Add Time',
  contact_header_message: 'Contacts Header Message',
  contact_header_message_hint: 'Manage contacts header message visible over contacts list and on contact form',
  call_header_message: 'Calls Header Message',
  call_header_message_hint: 'Manage calls header message visible over calls list and on contact form',
  application_message_color: 'Color',
  red: "Red",
  blue: 'Blue',
  green: 'Green',
  orange: 'Orange',
  request_time_off: 'Request Time Off',
  add_task: 'Add Task',
  display_name_alias: 'Display Name/Alias',
  real_name: 'Real Name',
  real_name_hint: 'Optional',
  life_line_988_lcc: '988 LCC',
  life_line_crisis_center: '988 Life Line Crisis Center',
  call_source: 'Source',
  call_result: 'Conversation Result',
  confirmed_connection_to_mental_healt_service: 'Confirmed Connection to Mental Health Service',
  confirmed_connection_to_other_services: 'Confirmed Connection to Other Service(s)',
  confirmed_connection_other_description: 'Description for Other Service(s) ',
  issue_management_logs: 'Issue Management Logs',
  issue_management_log: 'IMS',
  date_opened: 'Date Opened',
  logged_issue: 'Logged Issue',
  owner_name: 'Owner Name',
  ticket_date_opened: 'Ticket Date Opened',
  target_completion: 'Target Completion',
  actual_completion: 'Actual Completion',
  product: 'Product',
  resolution_action_plan: 'Resolution/Action Plan',
  was_the_further_action_voluntary_or_involuntary: 'Was the further action voluntary or involuntary?',
  cps_child_protective_services: 'CPS (Child Protective Services)',
  aps_adult_protective_services: 'APS (Adult Protective Services)',
  ombudsman: 'Ombudsman',
  voluntary: 'Voluntary',
  involuntary: 'Involuntary',
  id: 'Id',
  agency_name: 'Agency Name',

  name_of_the_person_you_spoke_with: 'Name of the person you spoke with',
  phone_number_of_the_person_you_spoke_with: 'Phone number of the person you spoke with',
  incident_number: 'Incident Number',
  did_you_send_the_written_report_via_fax_or_online: 'Did you send the written report via fax or online?',
  did_you_make_a_verbal_report: 'Did you make a verbal report?',
  calls_module: 'Calls Module',
  general: 'General',
  contacts_module: 'Contacts Module',
  mandated_reporting_forms: 'Mandated Reporting Forms',
  mandated_reporting_forms_hint: 'Manage mandated reporting links to forms in bottom of contact form',
  add_shift: 'Add Shift',
  shift_settings: 'Shift Settings',
  shift_settings_description: 'Manage shifts settings',
  call_details: 'Call Details',
  silent_monitoring: 'Silent Monitoring',
  notes_by: 'Notes By',
  call_taker: 'Call Taker',
  quality_monitoring: 'Quality Monitoring',

  good_contact_title: 'Good Contact',
  good_contact_hint: 'connection with the caller, alliance, rapport',

  suicide_assessment_title: 'Suicide Assessment',
  suicide_assessment_hint: 'exploring desire, intent, capability',

  getting_the_story_and_focusing_the_call_title: 'Getting the Story and Focusing the Call',
  getting_the_story_and_focusing_the_call_hint: 'What does the caller need the counselor to understand to feel heard and connected?',

  collaborative_problem_solving_title: 'Collaborative Problem Solving',
  ending_the_call_title: 'Ending the Call',

  using_correct_greeting: 'Using correct greeting',
  uses_a_warm_and_genuine_tone: 'Uses a warm and genuine tone',
  is_non_judgmental_accepting_and_respectful: 'Is non-judgmental, accepting, and respectful',
  uses_patience_in_matching_the_clients_language_and_pace: 'Uses patience in matching the client’s language and pace',
  gives_the_caller_space_to_collect_their_thoughts_as_needed: 'Gives the caller space to collect their thoughts, as needed',
  stays_engaged_and_present: 'Stays engaged and present',
  uses_open_ended_questions: 'Uses open ended questions',
  reflects_back_the_callers_feelings: 'Reflects back the caller’s feelings',
  determines_what_is_the_callers_emotional_state: 'Determines ’what is the caller’s emotional state?’',

  listened_for_breadcrumbs_talks_about_them_with_the_caller: 'Listened for breadcrumbs + talks about them with the caller\t',
  asked_directly_about_suicide: 'Asked directly about suicide',
  engaged_caller_in_open_discussion_of_suicidal_thoughtsbehavior_desire: 'Engaged caller in open discussion of suicidal thoughts/behavior (desire)',
  assessed_callers_intent_to_take_suicidal_action_in_the_near_term: 'Assessed caller’s intent to take suicidal action in the near term',
  explored_callers_access_to_means: 'Explored caller’s access to means',
  previous_history_of_suicide_attempts: 'Previous history of suicide attempts',
  discusses_if_caller_has_made_any_preparatory_behaviors_to_die_practice_notes_putting_affairs_in_order: 'Discusses if caller has made any preparatory behaviors to die (practice, notes, putting affairs in order)',
  explores_both_reasons_for_dying_and_for_living: 'Explores both reasons for dying & for living',
  attempts_get_immediate_distance_between_the_caller_and_the_means: 'Attempts get immediate distance between the caller and the means',

  listened_for_breadcrumbs: 'Listened for breadcrumbs',
  uses_reflections_and_summarizes_callers_feelings: 'Uses reflections and summarizes callers feelings',
  uses_open_ended_questions_when_helpful: 'Uses open-ended questions when helpful',
  avoids_going_into_investigative_mode: 'Avoids going into investigative mode',
  focuses_on_the_emotional_needs_of_the_caller: 'Focuses on the emotional needs of the caller',
  directs_the_caller_toward_the_present_as_needed_did_something_happen_today: 'Directs the caller toward the present, as needed. “Did something happen today?”',
  has_a_natural_not_scripted_use_of_skills_and_is_authentic_and_genuine: 'Has a natural, not scripted, use of skills & is authentic and genuine',
  does_not_jump_prematurely_to_problem_solving: 'Does not jump prematurely to problem-solving',

  discuss_a_coping_plan_low_risk_what_are_you_going_to_do_after_our_call: 'Discuss a coping plan (low risk) – “What are you going to do after our call?”',
  helps_develop_a_safety_plan_medium_high_risk : 'Helps develop a safety plan (medium – high risk)',
  helps_caller_identify_and_prioritize_problems_needs_and_wants: 'Helps caller identify and prioritize problems, needs, and wants',
  explore_what_the_caller_has_tried_to_do_to_solve_the_problem: 'Explore what the caller has tried to do to solve the problem',
  coping_strategies_that_can_be_done_alone: 'Coping strategies that can be done alone',
  coping_strategies_that_involve_someone_else: 'Coping strategies that involve someone else',
  detailed_means_safety_plan: 'Detailed means safety plan',
  someone_the_caller_can_spend_time_with_to_avoid_being_alone_during_peak_crisis: 'Someone the caller can spend time with to avoid being alone during peak crisis',
  offers_an_outreach_call_as_appropriate: 'Offers an outreach call as appropriate',
  avoids_taking_responsibility_to_fix_the_problem: 'Avoids taking responsibility to fix the problem',
  does_not_give_advice: 'Does not give advice',
  revisits_assessment_as_needed: 'Revisits assessment, as needed',
  notices_the_follow_of_the_call_and_wraps_up_at_an_appropriate_time: 'Notices the follow of the call and wraps up at an appropriate time',
  provides_a_transitional_phrase_to_indicate_that_the_call_is_ending: 'Provides a transitional phrase to indicate that the call is ending',
  had_the_caller_summarize_the_safety_or_coping_plan: 'Had the caller summarize the safety or coping plan',
  encourages_continued_use_of_the_crisis_lines: 'Encourages continued use of the crisis lines',
  pos: 'pos',
  availability: 'Availability',
  hide_tasks: 'Hide Tasks',
  hide_availability: 'Hide Availability',
  hide_activity: 'Hide Activity',
  hide_shift: 'Hide Shift',
  hide_change_request: 'Hide Change Request',
  event_filters: 'Filters',
  show_tasks: 'Show Tasks',
  show_shifts: 'Show Shifts',
  show_activities: 'Show Activities',
  show_change_requests: 'Show Change Requests',
  hide_activities : 'Hide Activities',
  hide_shifts: 'Hide shifts',
  show_availability: 'Show Availability',
  shift_roles_description: 'Manage the roles that are available for shifts',
  shift_roles: 'Shift Roles',
  generate_shifts: 'Generate Shifts',
  no_of_shifts: 'Number of Shifts',
  no_of_positions: 'Number of Positions',
  date_start: 'Date Start',
  date_end: 'Date End',
  generate_shifts_description: 'Generate shifts for a given date range. This will overwrite any existing shifts and clear positions for the given date range.',
  generate: 'Generate',
  shift: 'Shift',
  book_position: 'Book Position',
  work_location: 'Work Location',
  shift_role: 'Shift Role',
  shift_type: 'Shift Type',
  position_booked: 'Position Booked',
  taken: 'Taken',
  position_already_booked: 'Position already booked',
  shift_attendance: 'Shift Attendance',
  successes_and_kudos: 'Successes and Kudos',
  successes_and_kudos_hint: 'Highlight interactions with callers and with teammates that went well',
  feedback_related_to_client_care_teamwork_and_css: 'Feedback related to client care, teamwork, and CSS',
  feedback_related_to_client_care_teamwork_and_css_hint: 'Document feedback provided regarding knowledge, skills, and technical abilities related the client care and team work. Multiple conversations and callers can be documented here. Include links to the apricot call note when available. This can include calls you monitored and calls you debriefed',
  next_steps: 'Next Steps',
  next_steps_hint: 'What will the supervisor do to follow up? What will the responder do to follow up? What are responder’s commitments. Is there anyone else you need to inform?',
  additional_information_shift_position_hint: 'Use this field to document other things that might not be related skills development, counseling, or teamwork',
  preferred_skills: 'Preferred Skills',
  required_skills: 'Required Skills',
  add_next_position: 'Add Next Position',
  remote: 'Remote',
  arrived_late: 'Arrived Late',
  left_early: 'Left Early',
  present: 'Present',
  absent: 'Absent',
  awaiting_approval: 'Awaiting Approval',
  text_line: 'Text Line',
  desired: 'Desired',
  unavailable: 'Unavailable',
  undesired: 'Undesired',
  shift_booking_approval: 'Shift booking approval',
  validate_book_weekly_shifts: 'Validate book weekly shifts',
  validate_book_daily_shifts: 'Validate book daily shifts',
  daily_limit: 'Daily Limit',
  weekly_limit: 'Weekly Limit',
  this_week_shifts: 'This Week Shifts',
  this_month_shifts: 'This Month Shifts',
  shift_stats: 'Shift Stats',
  automate_shift_scheduler: 'Automate Shift Scheduler',
  folder_created: 'Folder created',
  change_folder_name: 'Change Folder Name',
  update_folder: 'Update Folder',
  folders: 'Folders',
  add_new_folder: 'Add New Folder',
  add_folder_name: 'Add Folder Name',
  add_folder: 'Add Folder',
  folder_deleted_successfully: 'Folder deleted successfully',
  please_select_folder: 'Please select a folder',
  documents: 'Documents',
  document_repository: 'Document Repository',
  enable_admin_calls: 'Enable Admin Calls',
  enable_admin_calls_hint: 'Enable admin calls creation from Call Handing Software',
  visible: 'Visible',
  register_call_information: 'Register Call Information',
  channel_uid: 'Channel UID',
  user_notes: 'Canned Responses',
  automatic_scheduler: 'Automatic Shift Scheduler',
  minutes: 'Minutes',
  notes_description: 'Manage all user Canned Responses in the system',
  positions: 'Positions',
  start_time: 'Start Time',
  end_time: 'End Time',
  shifts: 'Shifts',
  ex_select_is_null: 'Is empty',
  ex_select_is_not_null: 'Is not empty',
  ex_select_next_month: 'Next month',
  ex_select_next_30_days: 'Next 30 days',
  ex_select_next_7_days: 'Next 7 days',
  ex_select_today: 'Today',
  ex_select_next_week: 'Next week',
  ex_select_last_year: 'Last year',
  ex_select_last_month: 'Last month',
  ex_select_last_30_days: 'Last 30 days',
  ex_select_last_7_days: 'Last 7 days',
  ex_select_last_week: 'Last week',
  estimated_days: 'Estimated Days',
  crm_ticket_number: 'CRM Ticket',
  jira_ticket: 'Jira Ticket',
  psap_feedback: 'PSAP Feedback',
  last_reviewed_with_customer: 'Last Reviewed With Customer',
  desired_final_state: 'Desired Final State',
  percent_of_completion: 'Percent of Completion',
  sso_login: 'Login to CRM',
  fail_to_sso_authorize_try_again: 'Fail to SSO authorize, please try again',
  sso_authorization: 'SSO Authorization',
  sso_account_management: 'SSO Account Management',
  you_will_be_logged_in_shortly: 'You will be logged in shortly',
  ex_select_after_today: 'After Today',
  ex_select_before_today: 'Before Today',
  automatic_shift_assignment: 'Automatic Shift Assignment',
  limit_of_hours_assigned_to_employees: 'Limit of hours assigned to employees',
  maximum_assigned_hours_per_day: 'Maximum assigned hours per day',
  maximum_assigned_hours_per_week: 'Maximum assigned hours per week',
  proposed_employee: 'Proposed Employee',
  automatic_schedule_priority: 'Schedule Priority',
  proposed_employee_by_automatic_shift_scheduler: 'Proposed Employee by Automatic Shift Scheduler',
  automatic_schedule_priority_hint: 'Defines priority for assignment where 1 is the highest priority and 5 is the lowest',
  automatic_shift_scheduler: 'Automatic Shift Scheduler',
  number_of_priority_positions: 'Number of Priority Positions',
  copy_calendar_url: 'Copy Calendar URL',
  Events: 'Events',
  News: 'News',
  add_position: 'Add Position',
  position_added: 'Position Added',
  are_you_sure_you_want_to_remove_this_position: 'Are you sure you want to remove this position?',
  position_removed: 'Position Removed',
  mh_call_type: 'Type',
  mh_call: 'Conversation',
  search_elements: 'Search elements',
  text_input: 'Text Input',
  text_input_description: 'Single Line Text Input',
  number_input: 'Number Input',
  number_input_description: 'Input field that only allows numbers',

  one_column: 'One Column',
  one_column_description: 'A Column in Container',
  two_columns: 'Two Columns',
  three_columns: 'Three Columns',
  four_columns: 'Four Columns',
  datatable: 'Datatable',

  h1: 'H1 header',
  h1_description: 'HTML <h1> tag',
  h2: 'H2 header',
  h2_description: 'HTML <h2> tag',
  h3: 'H3 header',
  h3_description: 'HTML <h3> tag',
  h4: 'H4 header',
  h4_description: 'HTML <h4> tag',
  h5: 'H5 header',
  h5_description: 'HTML <h5> tag',
  h6: 'H6 header',
  h6_description: 'HTML <h6> tag',

  static_html: 'Static HTML',
  static_html_description: 'Plain HTML element',
  paragraph: 'Paragraph',
  paragraph_description: 'HTML <p> tag',
  two_columns_description: 'Two columns next to each other',
  three_columns_description: 'Three columns next to each other',
  four_columns_description: 'Four columns next to each other',
  container: 'Container',
  container_description: 'A container to group elements',
  link: 'Link',
  link_description: 'HTML <a> tag',
  divider: 'Divider',
  divider_description: 'HTML <hr> tag',
  multiselect_input: 'Multiselect Input',
  multiselect_input_description: 'A multiselect input field',
  email_input: 'Email Input',
  email_input_description: 'An input field that only allows email addresses',
  phone_input: 'Phone Input',
  phone_input_description: 'An input field that only allows phone numbers',
  url_input: 'URL Input',
  url_input_description: 'An input field that only allows URLs',
  textarea: 'Textarea',
  textarea_description: 'A textarea input',
  wyswyg_editor: 'WYSWYG Editor',
  wyswyg_editor_description: 'Rich text editor',
  checkbox: 'Checkbox',
  checkbox_description: 'A checkbox input',
  checkbox_group: 'Checkbox Group',
  checkbox_group_description: 'A group of checkboxes',
  radio: 'Radio',
  radio_description: 'Plain radio input',
  radio_group: 'Radio Group',
  radio_group_description: 'A group of radio inputs',
  toggle: 'Toggle',
  toggle_description: 'Toggle / switch button',
  datetime: 'Datetime',
  datetime_description: 'Date & time picker input',
  slider: 'Slider',
  slider_description: 'Horizontal slider',
  time: 'Time',
  structure: 'Structure',
  multiselect: 'Multiselect',
  static: 'Static',
  simulate: 'Simulate',
  time_description: 'Time picker input',
  tags_description: 'A group of tags',
  select_description: 'A select input',
  multiselect_description: 'A multiselect input',
  date_description: 'Date picker input',
  one_column_full_width: 'One Column Full Width',
  layout: 'Layout',
  css_class: 'CSS Class',
  prefix: 'Prefix',
  suffix: 'Suffix',
  before: 'Before',
  after: 'After',
  data: 'Data',
  mask: 'Mask',
  hint: 'Hint',
  default_value: 'Default Value',
  decorator: 'Decorator',
  input_css_class: 'Input CSS Class',
  standout: 'Standout',
  rounded: 'Rounded',
  square: 'Square',
  field_name: 'Field Name',
  tooltip: 'Tooltip',
  row: 'Row',
  copy: 'Copy',
  outlined: 'Outlined',
  placeholder: 'Placeholder',
  dense: 'Dense',
  borderless: 'Borderless',
  clearable: 'Clearable',
  filled: 'Filled',
  column_size: 'Column Size',
  validation: 'Validation',
  min_length: 'Min Length',
  max_length: 'Max Length',
  regex: 'Regex',
  default_hidden: 'Default Hidden',
  show_when_field: 'Show When Field',
  has_value: 'Has Value',
  visibility: 'Visibility',
  min_value: 'Min Value',
  max_value: 'Max Value',
  add_option: 'Add Option',
  vertical: 'Vertical',
  display_as_vertical_list: 'Display as vertical list',
  label_color: 'Label Color',
  bg_color: 'Background Color',
  forms: 'Forms',
  form_settings_description: 'Manage Forms',
  plural_name: 'Plural Name',
  schema: 'Schema',
  enable_comments: 'Enable Comments',
  enable_attachments: 'Enable Attachments',
  enable_history: 'Enable History',
  save_settings: 'Save Settings',
  duplicate_element_name: 'Form has duplicated names of inputs',
  form_data_valid: 'Form data valid',
  validate_form: 'Validate Form',
  duplicated_elements: 'Duplicated Elements',
  icon_hint: 'Example. "mdi-alphabetical-variant". Use icons from (mdi-v5) https://pictogrammers.com/library/mdi/',
  permission: 'Permission',
  Size: 'size',
  create_shift: 'Create Shift',
  shift_created: 'Shift Created',
  delete_shift: 'Delete Shift',
  are_you_sure_you_want_to_remove_this_shift: 'Are you sure you want to remove this shift?',
  shift_removed: 'Shift Removed',
  create_shift_description: 'Create a new shift',
  datetime_start: 'Start Date',
  datetime_end: 'End Date',
  call_type: 'Call Type',
  content_and_issues: 'Content and Issues',
  forms_data: 'Forms Data',
  hide_default_content_and_issues: 'Hide default content and issues',
  hide_further_action_911: 'Hide further action 911',
  hide_further_action_walfare_check: 'Hide further action walfare check',
  hide_further_action_follow_up: 'Hide further action follow up',
  hide_further_action_cps: 'Hide further action cps',
  hide_further_action_aps: 'Hide further action aps',
  hide_further_action_ombudsman: 'Hide further action ombudsman',
  hide_further_action_additional_information: 'Hide further action additional information',
  hide_further_was_further_action_voluntary: 'Hide further was further action voluntary',
  custom_call_form: 'Contact Form',
  calls_module_settings: 'Calls Module Settings',
  contact_module_settings: 'Contact Module Settings',
  hide_further_suicide_watch: 'Hide further action suicide watch',
  form_builder: 'Form Builder',
  enable_custom_call_form_setting: 'Enable custom contact form settings',
  counter: 'Counter',
  form: 'Form',
  are_you_sure_you_want_to_clear_address_info: 'Are you sure you want to clear address info?',
  clear_address_info: 'Clear Address Info',
  verified: 'Verified',
  employee_type: 'Employee Type',
  number_of_staff: 'Number of Staff',
  number_of_part_staff: 'Number of Part Staff',
  number_of_volunteers: 'Number of Volunteers',
  request_substitution: 'Request Substitution',
  request_substitution_success: 'Request Substitution Success',
  emails: 'Emails',
  shift_employee: 'Shift Employee',
  request_substitution_message: 'Request Substitution Message',
  request_substitution_message_description: 'Default Request Substitution Message',
  shift_reminder_messages: 'Shift Reminder Messages',
  shift_reminder_message: 'Shift Reminder Message',
  shift_reminder_message_description: 'Shift reminder message for next day users',
  shift_open_position_reminder_message: 'Shift Open Position Reminder Message',
  shift_open_position_reminder_message_description: 'Reminder send to users who have not assingeds shifts for the next day',
  custom_request_substitution_message: 'Request Substitution Message',
  lumen: 'Lumen',
  lumen_settings_description: 'Lumen integration settings',
  atos_settings_description: 'Atos integration settings',
  atos_settings: 'Atos Settings',
  environment: 'Environment',
  sandbox_consumer_key: 'Sandbox Consumer Key',
  sandbox_consumer_secret: 'Sandbox Consumer Secret',
  production_consumer_key: 'Production Consumer Key',
  production_consumer_secret: 'Production Consumer Secret',
  lumen_integration_enabled: 'Lumen integration enabled',
  sandbox: 'Sandbox',
  production: 'Production',
  ebond: 'Ebond',
  integrator: 'Integrator',
  agree_email_contact: 'Agree To Email Contact',
  agree_phone_contact: 'Agree To Phone Contact',
  follow_up_preferences: 'Follow Up Preferences',
  contact_preferences: 'Contact Preferences',
  hide_worker: 'Hide worker field',
  hide_contact: 'Hide contact field',
  dark_mode: 'Dark Mode',



  current: 'Current',
  survey_email: 'Survey Email',
  survey_phone: 'Survey Phone',
  satisfaction_survey: 'Satisfaction Survey',
  is_survey: 'Is Survey',
  are_you_sure_you_want_to_send_survey: 'Are you sure you want to send survey?',
  send_survey: 'Send Survey',
  send_sms: 'Send SMS',
  send_email: 'Send Email',
  sms_not_implemented_yet: 'SMS not implemented yet',
  survey_sent: 'Survey Sent',
  survey_message: 'Survey Message',
  survey_message_description: 'Default Survey Email Message',
  thank_you_page_message: 'Thank You Page Message',
  thank_you_page_message_description: 'Default Thank You Page Message',
  send: 'Send',
  twilio_from_number: 'Twilio Phone Number',
  twilio_from_number_description: 'Provide a Twilio phone number to send SMS messages (ex. +13701xxxxxx).',
  survey_message_sms: 'Survey Message SMS',
  survey_message_sms_description: 'Default Survey SMS Message',
  sms_too_long: 'SMS message is too long',
  please_wait_shift_scheduler_is_preparing_data: "<b>Please wait. Shift Scheduler is preparing data for automatic scheduling.</b>",
  reports: 'Reports',
  monthly_submission_reports: 'Monthly Submission Reports',
  quarterly_submission_reports: 'Quarterly Submission Reports',
  report_date: 'Report Date',
  download_excel: 'Download Excel',
  mh_call_categories: 'Contact Categories',
  mh_call_categories_description: 'Manage contact categories',
  mh_call_crisis: 'Contact Crisis Categories',
  mh_call_crisis_description: 'Manage call crisis categories',
  mh_call_risks: 'Contact Risk Category',
  mh_call_risks_description: 'Manage call risk categories',
  call_category: 'Contact Category',
  crisis_category: 'Crisis Category',
  crisis_categories: 'Crisis Categories',
  risk_category: 'Risk Category',
  mh_call_referral_categories: 'Contact Referral Categories',
  mh_call_referral_categories_description: 'Manage contact referral categories',
  referrals_category: 'Referrals Category',
  on_site_support_name: 'Support Person Comments',
  department: 'Department',
  department_uid: 'Nexis Department ID',
  nexisChat: 'Conversation (Chat,Txt,Email)',
  add_new_profile: 'Add New Profile',
  risk_start: 'Risk Start',
  risk_end: 'Risk End',
  trigger_nexis_message_discussion:'Trigger Nexis Message Discussion',
  connect_with_jira: 'Create Ticket in Jira',
  nexis_trigger: 'Nexis Trigger',
  nexis_trigger_description: 'Manage Nexis Triggers',
  products: 'Products',
  products_description: 'Manage products',
  channel: 'Channel',
  channelName: 'Channel Name',
  channel_id: 'Channel ID',
  channel_name: 'Channel Name',
  nexis_message_triggered: 'Nexis Message Triggered',
  military_status: 'Military Status',
  military_status_comment: 'Military Status Comment',
  conversation_outcome: 'Conversation Outcome',
  declined_to_answer: 'Declined to Answer',
  did_not_ask: 'Did not Ask',
  is_supervisor: 'Is Supervisor',
  jira: 'Jira',
  jira_settings_description: 'Jira Integration Settings',
  jira_user: 'Jira User (ex. crm@nga911.com)',
  jira_host: 'Jira Host (ex. https://nga911.atlassian.net)',
  jira_personal_access_token: 'Personal Access Token',
  jira_ticket_summary_template: 'Ticket Summary Template',
  jira_ticket_description_template: 'Ticket Description Template',
  variables: 'Variables',
  create_jira_ticket: 'Create Jira Ticket',
  assign_jira_ticket: 'Connect Existing Jira Ticket',
  jira_ticket_not_found: 'Jira Ticket Not Found',
  jiraIssueKey: 'Jira Issue Key',
  jira_ticket_assigned: 'Jira Ticket Assigned',
  unassign_jira_ticket: 'Unassign Jira Ticket',
  jira_ticket_unassigned: 'Jira Ticket Unassigned',
  are_you_sure_you_want_to_unassign_this_ticket: 'Are you sure you want to unassign this ticket?',
  jira_ticket_created: 'Jira Ticket Created',
  project: 'Project',
  summary: 'Summary',
  field_service: 'Field Service',
  primary_field_tech: 'Primary Field Technician',
  secondary_field_tech: 'Secondary Field Technician',
  field_tech_manager: 'Field Technician Manager',
  go_to_nexis: 'Go to Nexis',
  supervisors: 'Supervisors',
  supervisors_added: 'Supervisors Added',
  workTitle: 'Work Title',
  officePhone: 'Office Phone',
  mobilePhone: 'Mobile Phone',
  department_name: 'Department Name',
  dispatched_time: 'Dispatched Time',
  technician_comments: 'Technician Comments',
  assigned_technician: 'Assigned Technician',
  start_call_time: 'Start Call Time',
  end_call_time: 'End Call Time',
  create_follow_up_task: 'Create Follow Up Task',
  synchronize_google_calendar: 'Synchronize Google Calendar',
  skill_type: 'Skill Type',
  strength_of_sale_qualified: 'Strength of Sale Qualified',
  pain_level: 'Pain',
  power_level: 'Power',
  vision_level: 'Vision',
  value_level: 'Value',
  collaborate_level: 'Collaborate',
  crta_level: 'CRTA',
  opportunities: 'Opportunities',
  prospect_qualification: 'Prospect Qualification',
  a_business_problem_critical_issue_or_missed_opportunity : 'A business problem, critical issue, or missed opportunity',
  someone_who_has_the_influence_and_or_authority_to_make_a_buying_decision : 'Someone who has the influence and/or authority to make a buying decision',
  capabilities_needed_to_eliminate_the_reasons_for_the_pain : 'Capabilities needed to eliminate the reasons for the pain',
  total_benefits_of_the_solution_minus_total_cost : 'Total benefits of the solution minus total cost',
  ability_to_direct_or_manage_the_buying_process_together_with_the_buyer : 'Ability to direct or manage the buying process together with the buyer',
  a_compelling_reason_to_act : 'A compelling reason to act',
  compelling_reason_to_act_crta: 'Compelling Reason to Act (CRTA)',
  opportunity_date: 'Opportunity Date',
  how_to_add: 'How To Add',
  privacy_policy: 'Privacy Policy',
  terms_of_use: 'Terms of Use',
  homepage: 'Home Page',
  task_notifications: 'Task Notifications',
  task_reminder_subject: 'Task Reminder Subject',
  task_reminder_message: 'Task Reminder Message',
  reported_at: 'Reported At',
  trouble_end_date: 'Trouble End Date - UTC',
  psap_local_time: 'PSAP Local Time',
  utc_date_hint: 'UTC Date & Time',
  merge_record: 'Merge Record',
  choose_record_to_merge: 'Choose Record To Merge',
  showOnlyDifferences: 'Show Only Differences',
  first_record: 'First Record',
  second_record: 'Second Record',
  lgu_class_id: 'LGU Class',
  contact_id: 'Contact',
  select_all_records: 'Select All Records',
  select_second_record_first: 'Please select second record first',
  records_merged: 'Records Merged',
  country_id: 'Country',
  state_id: 'State',
  county_id: 'County',
  mh_call_type_id: 'Call Type',
  ethnicity_id: 'Ethnicity',
  mh_category_id: 'Category',
  mh_start_risk_id: 'Start Risk',
  mh_end_risk_id: 'End Risk',
  mh_referral_category_id: 'Refferal Category',
  mh_call_crisis_category_name: 'Crisis Category',
  language_id: 'Language',
  orientation_id: 'Orientation',
  phone_type_id: 'Phone Type',
  created_by_id: 'Created By',
  legal_hold: 'Legal Hold',
  address_verified: 'Address Verified',
  manual_fill: 'Manual Fill',
  resulted_in_emergency_rescue: 'Contact resulted in emergency rescue',
  general_product: 'General Product',
  general_products: 'General Products',
  general_products_description: 'Manage general products',
  social_media: 'Social Media',
  industry_id: 'Industry',
  rating_id: 'Rating',
  send_ticket_notification: 'Send Ticket Notification',
  contact_status: 'Contact Status',
  contact_country: 'Contact Country',
  age_id: 'Age',
  gender_id: 'Gender',
  solution_type: 'Solution Type',
  updated_by: 'Updated By',
  contact_list: 'Contact List',
  additional_contacts: 'Additional Contacts',
  additional_ticket_contacts: 'Additional Ticket Contacts',
  additional_contact_list: 'Additional Contact List',
  contact_callback_number: 'Callback Number',
  generate_and_fill: 'Generate and Fill',
  regenerate_report_data: 'Regenerate Report Data',
  report_data_regenerated: 'Report Data Regenerated',
  choose_month: 'Choose Month',
  report_data_generated: 'Report Data Generated',
  ldb_inquiry: '9-1-1 LDB Inquiry',
  call_time: 'Call Time',
  pmo_date_corrected: 'PMO Date Corrected',
  call_taker_name: 'Call Taker Name',
  call_taker_email: 'Call Taker Email',
  call_taker_phone: 'Call Taker Phone',
  pmo_service_provided: 'PMO Service Provided',
  pmo_date_sdp_contacted: 'PMO Date SDP Contacted',
  answering_psap: 'Answering PSAP',
  answering_psap_details: 'Answering PSAP Details',
  pmo_communication: 'Pmo Communication',
  ani_display: 'Ani Display',
  ali_display_information: 'Ali Display Information',
  callers_information: 'Callers Information',
  no_display: 'No Display',
  record_not_found: 'Record Not Found',
  misroute: 'Misroute',
  wrong_display_of: 'Wrong Display Of',
  telephone_number: 'Telephone Number',
  community: 'Community',
  pilot_number: 'Pilot #',
  class_of_service: 'Class Of Service',
  house_number: 'House #',
  apt_or_ste_number: 'Apt or Ste #',
  street_dir_name: 'Street Dir/Name',
  pd: 'PD',
  fd: 'FD',
  ems_text: 'EMStext',
  ems: 'EMS',
  new: 'New',
  resolved: 'Resolved',
  ali_display: 'ALI Display',
  incorrect_address: 'Incorrect Address',
  thank_you_for_your_inquiry: 'Thank you for your inquiry!',
  are_you_sure_you_want_to_send_inquiry: 'Are you sure you want to send inquiry?',
  send_another: 'Send Another',
  generate_public_url: 'Generate Public Url',
  choose_psap_location: 'Choose PSAP Location',
  internal_comment_label: 'Internal Comment',
  external_comment_label: 'Customer Facing Comment',
  internal_comment_ticket_label: 'Internal Comment',
  external_comment_ticket_label: 'Customer Facing Comment',
  add_external_comment_confirmation: 'Are you sure you want to add this comment?',
  ticket_status_timestamp: 'Ticket Statuses Periods',
  ticket_status_times: 'Ticket Statuses Times',
  refresh_statuses_times: 'Refresh Statuses Times',
  merge: 'Merge',
  refresh_ticket_open_duration: 'Refresh Ticket Open Duration',
  ticket_open_duraion: 'Ticket Open Duration',
  no_trouble_end_date: 'No Trouble End Date',
  no_trouble_start_date: 'No Trouble Start Date',
  ldb_settings: 'Ldb Settings',
  ldbs_settings_description: 'Setup Ldb Notifications',
  ldb_notification_enabled: 'Ldb Notification Enabled',
  ldb_notification_emails: 'Notification Emails',
  ldb_notification_nexis_flow_webhook: 'Nexis Flow Webhook',
  ldb: 'Ldb',
  ldb_notification_emails_hint: 'Comma separated list of emails',
  serverity_levels: 'Serverity Levels',
  serverity_levels_description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, consequat nunc. Nulla nec purus feugiat, molestie ipsum et, consequat nunc.',
  not_required: 'Not Required',
  ticket_service_categories: 'Service Ticket Categories',
  ticket_service_categories_description: 'Manage Service Ticket Categories',
  ticket_priority: 'Ebonded Priority',
  your_timezone: 'Your Timezone',
  prev_contacts_by_phone: 'Prev. Contacts by Phone',
  prev_contacts_by_email: 'Prev. Contacts by Email',
  notification_types: 'Notification Types',
  leave_empty_for_all_severities: 'Leave empty for check for all severities',
  leave_empty_for_all_cases: 'Leave empty for check for all cases',
  ticket_notification_settings: 'Ticket notification Settings',
  notification_type: 'Notification Type',
  enabled: 'Enabled',
  custom_date_time_comment: 'Enter date & time in your timezone. It will be converted to UTC date & time.',
  rca_time: 'RCA Time',
  ticket_email_template: 'Ticket Email Template',
  ticket_assigned_email_template_subject: 'Ticket Assigned Email Subject',
  ticket_assigned_email_template_message: 'Ticket Assigned Email Message',
  ticket_not_resolved_email_template_subject: 'Ticket Not Resolved Email Subject',
  ticket_not_resolved_email_template_message: 'Ticket Not Resolved Email Message',
  rca_due_date: 'RCA Due Date',
  rca_finish_date: 'RCA Finish Date',
  account_information: 'Account Information',
  account_notifications: 'Account Notifications',
  contact_notifications: 'Contact Notifications',
  editable: 'Editable',
  email_communication: 'Email Communication',
  body: 'Body',
  error: 'Error',
  sent_at: 'Sent At',
  rca_due_ticket_not_resolved: 'Root Cause Analisys - Ticket Not Resolved',
  show: 'Show',
  public: 'Public',
  are_you_sure_you_want_to_change_it_to_private: 'Are you sure you want to change it to private?',
  contracts: 'Contracts',
  start_date_time: 'Start Date Time',
  end_date_time: 'End Date Time',
  delivered: 'Delivered',
  reviewed: 'Reviewed',
  accepted: 'Accepted',
  quotes: 'Quotes',
  quantity: 'Quantity',
  line_item: 'Line Item',
  unit_price: 'Unit Price',
  valid_until: 'Valid Until',
  emergency_rescue_law_enforcement: 'Emergency rescue with law enforcement?',
  monthly_submission_reports_v1: 'Monthly Submission Report V1',
  monthly_submission_reports_v2: 'Monthly Submission Report V2',
  related_with: 'Related With',

  financial_penalties_submitted: 'Financial Penalties Submitted for ticket',
  financial_penalties: 'Financial Penalties',
  financial_penalties_description: 'Description',
  activities: 'Activities',
  activity: 'Activity',
  meeting: 'Meeting',
  note: 'Note',
  call_outcome: 'Call Outcome',
  call_direction: 'Call Direction',
  date_time: 'Date Time',
  outcome: 'Outcome',
  due_date: 'Due Date',
  task: 'Task',
  email_address: 'Email Address',
  phone_number: 'Phone Number',
  email_direction: 'Email Direction',
  conversations: 'Conversations',
  your_ongoing_conversations: 'Your Ongoing Conversations',
  ongoing: 'Ongoing',
  quote: 'Quote',
  contract: 'Contract',
  unsaved: 'Unsaved',
  atos: 'Atos',
  postal_mail: 'Postal Mail',
  sms: 'SMS',
  busy: 'Busy',
  connected: 'Connected',
  left_live_message: 'Left Live Message',
  left_voice_mail: 'Left Voice Mail',
  no_answer: 'No Answer',
  wrong_number: 'Wrong Number',
  inbound: 'Inbound',
  outbound: 'Outbound',
  scheduled: 'Scheduled',

  rescheduled: 'Rescheduled',
  no_show: 'No Show',
  canceled: 'Canceled',
  whatsapp: 'WhatsApp',
  linked_in: 'LinkedIn',
  facebook: 'Facebook',
  medium: 'Medium',
  todo: 'To Do',
  select_activity: 'Select Activity',
  activity_type: 'Activity Type',
  related_with_other_rows: 'Related With Other Rows',
  import_activities_fields: 'Import Activities Fields',
  budget_confirmed: 'Budget Confirmed',
  maintenance_windows: 'Maintenance Windows',
  maintenance_emails: 'Maintenance E-mails',
  work_description: 'Work Description',
  recommended_actions: 'Recommended Actions',
  impacted_service: 'Impacted Services',
  service: 'Service',
  customer: 'Customer',
  are_you_sure_you_want_to_send_email: 'Are you sure you want to send email?',
  email_sent: 'Email Sent',


  acknowledge_by: 'Acknowledge By',
  invalid_token: 'Invalid Token. Please contact support',
  impacted_services: 'Impacted Services',
  location_and_address: 'Location and Address',
  multi_type: 'Multi-Type',
  chat: 'Chat',
  email_sending_type: 'Email Sending Type',
  step_by_step_tutorial: 'Step by Step Tutorial',
  send_emails: 'Send Emails',
  invalid_email_array: 'Invalid email in list',
  ask_for_confirmation_that_the_message_has_been_read: 'Ask for confirmation that the message has been read',
  reset_shift_filters: 'Reset Shift Filters',
  case_tracker: 'Case Tracker',
  case_trackers: 'Case Tracker',
  case_start_date: 'Case Start Date',
  case_end_date: 'Case End Date',
  closed_by: 'Closed By',
  sales: 'Sales',

  first_name: "First Name",
  last_name: "Last Name",

  crm_reports: 'CRM Reports',
  dwh_reports: 'DWH Reports',

  calls_resulting_in_tickets: 'Calls Resulting in Tickets',
  sla_penalties: 'SLA Penalties',
  tickets_opened_to_tickets_closed: 'Tickets Opened to Tickets Closed',
  tickets_resulting_on_site_support: 'Tickets Resulting on Site Support',
  tickets_service_category: 'Tickets Service Category',

  tickets_with_on_site_supportticket_per_severity: 'Ticket Per Severity',
  select_date_range: 'Date Range',
  tickets_met_threshold: 'Tickets Met Threshold',
  percent_met: 'Percent Met',
  this_year: 'This Year',
  today: 'Today',
  yesterday: 'Yesterday',
  this_month: 'This Month',
  last_7_days: 'Last 7 Days',
  last_month : 'Last Month',
  last_30_days: 'Last 30 Days',
  ticket_open_date: 'Ticket Open Date',
  ticket_close_date: 'Ticket Close Date',
  percentage_calls_resulting_in_tickets: 'Percentage Calls Resulting in Tickets',
  ticket_not_met: 'Ticket Not Met',
  percentage_ticket_met_threshold: 'Percentage Ticket Met Threshold',
  ticket_met_threshold: 'Ticket Met Threshold',
  service_category_name: 'Service Category Name',
  service_requests: 'Service Requests',
  percent_with_on_site_support: 'Percent With On Site Support',
  tickets_with_on_site_support: 'Tickets With On Site Support',
  ticket_per_severity: 'Ticket Per Severity',
  export_raw: 'Export Raw',
  export : 'Export',

  call_volume_per_range_of_call_taker_answer_time: 'Call Volume Per Range of Call Taker Answer Time',
  view: 'View',
  number_of_calls: 'Number of Calls',
  range_of_call_taker_answer_time: 'Range of Call Taker Answer Time',
  filters: 'Filters',
  view_name: 'View Name',
  agent_name: 'Agent Name',
  call_summary: 'Calls Summary',
  grouping: 'Grouping',
  grouping_period: 'Grouping Period',
  call_summary_pivot: 'Pivot Calls Summary',
  call_per_hour: 'Calls Per Hour',
  average_call_duration: 'Average Call Duration',
  calls_by_agent: 'Calls By Agent',
  agent_ring_time: 'Agent Ring Time',
  psap_ring_time: 'PSAP Ring Time',
  daily_call_taker_call_volume_per_hour_of_they_day: 'Daily Call Taker Call Volume Per Hour of The Day',
  reloading_page: 'Reloading Page to apply color change',
  agent_call_summary: 'Agent Call Summary',
  dark_mode_enabled: 'Dark Mode Enabled. Reloading',
  light_mode_enabled: 'Light Mode Enabled. Reloading',
  call_log: 'Call Log',
  call_recordings: 'Call Recordings',
  call_id: 'Call Id',
  filament_admin_panel: 'Open Filament Admin Panel',
  filament_admin_panel_description: 'User Filamen Admin Panel to Manage Views, List of PSAP, Connect Users with Views and PSAP',
  call_recordings_search_hint: 'By Call Id, Phone Number, Caller, Callee, Agent, Sip',
  call_recording: 'Call Recording',
  callee_id_name: 'Callee Name',
  callee_id_number: 'Callee Number',
  caller_id_name: 'Caller Name',
  caller_id_number: 'Caller Number',
  call_summary_detailed: 'Detailed Call Summary',
  call_time_from: 'Call Time From',
  call_time_to: 'Call Time To',
  dropped_call: 'Dropped',
  clear_filters: 'Clear Filters',
  filters_cleared: 'Filters Cleared',

}
